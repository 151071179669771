import React, { useEffect, useState } from 'react';
import ContactCardNew from '../components/ContactCardNew';
import ContactFormNew from '../components/ContactFormNew';
import KModal from '../components/KModal';
import { Button, Input, Flex, Typography, Select, Modal, Empty, message } from 'antd';
import { ExclamationCircleFilled, PlusOutlined, SearchOutlined } from '@ant-design/icons';
import styles from '../style/Contact.module.css';
import { useDispatch, useSelector } from 'react-redux';
// 这里全部使用 supplierContactSlice 中的 action 和 thunk
import {
  getSupplierContactsList,
  createSupplierContact,
  updateSupplierContact,
  deleteSupplierContact,
  deleteSupplierContacts,
  getSupplierRoutesList,
  getSupplierRouteDetails,
  updateSupplierRouteDetails,
  deleteSupplierRouteById,
  createSupplierRoute,
  updateSupplierContactId,
  updateSupplierEditOpen,
  updateSupplierOpen,
  updateSupplierSearchData,
  updateSupplierSelectedContacts,
  updateSupplierSelectedRoute,
  updateSupplierShowSelectRoutesModal,
  updateSupplierSelectedRouteForCustomers,
  addSupplierCustomerToRouteThunk,
  updateSupplierShowViewModal,
  updateSupplierCustomerInvoicesLoading,
  updateSupplierShowSelectContactMode, // 用于切换供应商联系人选择模式
} from '../store/reducers/supplierContactSlice';
import { getInvoiceListByCustomer } from '../store/reducers/invoiceSlice';
import ViewCustomerInvoicesModal from '../components/ViewCustomerInvoicesModal';
import { updateShowSelectMode } from '../store/reducers/commonSlice';

const { confirm } = Modal;
const { Title } = Typography;
const { Option } = Select;

const Todolist = () => {
  // 从 supplierContact 中读取状态数据，包括选择模式标识
  const {
    contacts,
    contactId,
    editOpen,
    open,
    searchData,
    selectedContacts,
    routes,
    selectedRoute,
    showSelectRoutesModal,
    selectedRouteForCustomers,
    showViewModal,
  } = useSelector((state) => state.supplierContact);
  const { showSelectTodoListMode } = useSelector((state) => state.common);

  const dispatch = useDispatch();
  const [sortOrder, setSortOrder] = useState('');

  useEffect(() => {
    // 加载供应商联系人和路线列表
    dispatch(getSupplierContactsList());
    dispatch(getSupplierRoutesList());
  }, [dispatch]);

  useEffect(() => {
    if (selectedRoute) {
      dispatch(getSupplierRouteDetails(selectedRoute));
    } else {
      dispatch(getSupplierContactsList());
    }
  }, [selectedRoute, dispatch]);

  // 新增任务（供应商联系人）
  const addNewContact = async (customer_name, email, customer_address, city, postcode, phone) => {
    dispatch(
      createSupplierContact({
        customer_name,
        email,
        customer_address,
        city,
        postcode,
        phone,
      }),
    );
  };

  // 搜索过滤
  let searchContacts = contacts.filter((contact) =>
    contact.customer_name.toLowerCase().includes(searchData.toLowerCase()),
  );

  // 排序逻辑（名称升序/降序）
  if (sortOrder === 'asc') {
    searchContacts = searchContacts.sort((a, b) => a.customer_name.localeCompare(b.customer_name));
  } else if (sortOrder === 'desc') {
    searchContacts = searchContacts.sort((a, b) => b.customer_name.localeCompare(a.customer_name));
  }

  // 修改任务
  const _updateContact = async (customer_name, email, id, customer_address, city, postcode, phone) => {
    dispatch(
      updateSupplierContact({
        customer_name,
        email,
        id,
        customer_address,
        city,
        postcode,
        phone,
      }),
    );
  };

  // 删除单个任务
  const _deleteContact = (id) => {
    confirm({
      title: 'Delete Confirm',
      icon: <ExclamationCircleFilled />,
      content: '是否删除？Confirm deleting?',
      async onOk() {
        dispatch(deleteSupplierContact(id));
      },
      onCancel() {
        console.log('Cancel');
      },
    });
  };

  // 打开编辑任务窗口
  const _openEditContact = (id) => {
    dispatch(updateSupplierContactId(id));
    dispatch(updateSupplierEditOpen(true));
  };

  // “全选”按钮逻辑
  const handleSelectAll = () => {
    dispatch(updateSupplierSelectedContacts(searchContacts));
  };

  // 切换单个任务的选中状态
  const handleSelectChange = (value) => {
    if (selectedContacts.some((contact) => contact.id === value.id)) {
      dispatch(
        updateSupplierSelectedContacts(
          selectedContacts.filter((contact) => contact.id !== value.id),
        ),
      );
    } else {
      dispatch(updateSupplierSelectedContacts([...selectedContacts, value]));
    }
  };

  // “取消”按钮逻辑：清空选中状态，并关闭选择模式
  const handleSelectCancel = () => {
    dispatch(updateSupplierSelectedContacts([]));
    dispatch(
      updateShowSelectMode({
        modeName: 'showSelectContactMode',
        show: false,
      }),
    );
  };

  // 选择路线（更新选中的路线）
  const handleRouteSelected = (value) => {
    dispatch(updateSupplierSelectedRoute(value));
  };

  // 打开“添加到路线”模态框
  const openSelectRouteModal = () => {
    dispatch(updateSupplierShowSelectRoutesModal(true));
  };

  // 选择模态框中选中的路线
  const handleRouteSelectedForCustomers = (value) => {
    dispatch(updateSupplierSelectedRouteForCustomers(value));
  };

  // 将选中的任务添加到指定路线
  const handleAddCustomersToRoute = () => {
    if (selectedRouteForCustomers) {
      dispatch(
        addSupplierCustomerToRouteThunk({
          routeId: selectedRouteForCustomers,
          customerIds: selectedContacts.map((customer) => customer.id),
        }),
      ).then(() => {
        dispatch(
          updateShowSelectMode({
            modeName: 'showSelectTodoListMode',
            show: false,
          }),
        );
      })
    } else {
      message.info('Please select a route.');
    }
  };

  // 查看任务详情（例如查看发票信息）
  const openViewModal = (contact) => {
    dispatch(updateSupplierShowViewModal(true));
    dispatch(updateSupplierCustomerInvoicesLoading(true));
    dispatch(getInvoiceListByCustomer(contact));
  };

  // “分享”按钮逻辑：复制选中任务的信息到剪贴板
  const handleShare = () => {
    const text = selectedContacts
      .map(
        (contact) => `
          id: ${contact.id},
          customer name: ${contact.customer_name},
          email: ${contact.email},
          customer address: ${contact.customer_address},
          phone: ${contact.phone},
          city: ${contact.city},
          driver name: ${contact.driver_name},
          postcode: ${contact.postcode}
        `,
      )
      .join('\n');
    const textArea = document.createElement('textarea');
    textArea.value = text;
    document.body.appendChild(textArea);
    textArea.select();
    textArea.setSelectionRange(0, 99999); // 兼容移动设备
    document.execCommand('copy');
    document.body.removeChild(textArea);
    message.success('Customers copied to clipboard!');
  };

  // “删除”按钮逻辑：批量删除选中任务
  const handleDelete = () => {
    if (selectedContacts.length > 0) {
      confirm({
        title: 'Delete Confirm',
        icon: <ExclamationCircleFilled />,
        content: 'Confirm deleting?',
        async onOk() {
          dispatch(deleteSupplierContacts(selectedContacts.map((contact) => contact.id)));
        },
        onCancel() {
          console.log('Cancel');
        },
      });
    } else {
      message.info('Please select at least one contact.');
    }
  };

  // 根据 contactId 找到当前选中任务（用于编辑）
  const selectContact = contacts.find((contact) => contact.id === contactId);

  return (
    <>
      {/* 新增任务模态框 */}
      <KModal
        isOpen={open}
        title={'Add New Task'}
        onOpen={() => dispatch(updateSupplierOpen(true))}
        onClose={() => dispatch(updateSupplierOpen(false))}
      >
        <ContactFormNew
          addNewContact={addNewContact}
          onClose={() => dispatch(updateSupplierOpen(false))}
        />
      </KModal>

      {/* 编辑任务模态框 */}
      <KModal
        isOpen={editOpen}
        title={'Update Task'}
        onOpen={() => dispatch(updateSupplierEditOpen(true))}
        onClose={() => dispatch(updateSupplierEditOpen(false))}
      >
        <ContactFormNew
          updateContact={_updateContact}
          contact={selectContact}
          onClose={() => dispatch(updateSupplierEditOpen(false))}
        />
      </KModal>

      {/* 选择路线模态框 */}
      <Modal
        title="Select Route"
        okText="Confirm"
        open={showSelectRoutesModal}
        onOk={handleAddCustomersToRoute}
        onCancel={() => dispatch(updateSupplierShowSelectRoutesModal(false))}
      >
        <Select
          placeholder="Task category"
          style={{ width: '100%' }}
          value={selectedRouteForCustomers}
          onChange={handleRouteSelectedForCustomers}
        >
          {routes.map((route) => (
            <Option key={route.id} value={route.id}>
              {route.name}
            </Option>
          ))}
        </Select>
      </Modal>

      <ViewCustomerInvoicesModal />

      <div>
        <Flex className={styles.savedContacts} justify="center"   style={{ marginTop: "40px" }} align="center">
          <img src="/banner.png" width="150px" height="100px" alt="banner" />
          <Title className={styles.title}>To-do-list</Title>
        </Flex>

        <div className={styles.pContent}>
          <Button
            type="primary"
            block
            onClick={() => dispatch(updateSupplierOpen(true))}
            icon={<PlusOutlined />}
          >
            Add New Task
          </Button>
        </div>

        <div className={styles.pContent}>
          <Select
            placeholder="Sort By"
            style={{ width: '100%' }}
            onChange={(value) => setSortOrder(value)}
          >
            <Option value="asc">Name A-Z</Option>
            <Option value="desc">Name Z-A</Option>
          </Select>
        </div>

        {/* 路线下拉选择 */}
        <div className={styles.pContent}>
          <Select
            placeholder="My Tasks"
            style={{ width: '100%' }}
            onChange={(value) => {
              if (value === 'top') {
                handleRouteSelected(null);
              } else {
                handleRouteSelected(value);
              }
            }}
            allowClear
          >
            <Option value="top">Default (no chosen routes)</Option>
            {routes.map((route) => (
              <Option key={route.id} value={route.id}>
                {route.name}
              </Option>
            ))}
          </Select>
        </div>

        <div className={styles.pContent}>
          <Input
            size="large"
            type="text"
            placeholder="Search Task..."
            prefix={<SearchOutlined />}
            value={searchData}
            onChange={(e) => dispatch(updateSupplierSearchData(e.target.value))}
          />
        </div>

        {/* 任务列表 */}
        <div className={styles.pContent}>
          {searchContacts.length > 0 ? (
            searchContacts.map((contact) => (
              <ContactCardNew
                contact={contact}
                key={contact.id}
                handleEdit={_openEditContact}
                deleteContact={_deleteContact}
                selecting={showSelectTodoListMode}
                selected={selectedContacts.some((c) => c.id === contact.id)}
                handleSelectChange={() => handleSelectChange(contact)}
                openViewModal={() => openViewModal(contact)}
              />
            ))
          ) : (
            <Empty />
          )}
        </div>

        {/* 当激活供应商版“选择模式”时显示操作按钮 */}
        {showSelectTodoListMode && (
          <div>
            <Flex className="actionTopLeft">
              <Button type="primary" onClick={handleSelectAll}>
                全选
              </Button>
            </Flex>
            <Flex className="actionTopRight">
              <Button type="primary" onClick={handleSelectCancel}>
                取消
              </Button>
            </Flex>
            <Flex className="actionBottomLeft">
              <Button type="primary" onClick={handleShare}>
                分享
              </Button>
              <Button type="primary" onClick={openSelectRouteModal}>
                添加到任务分类
              </Button>
            </Flex>
            <Flex className="actionBottomRight">
              <Button type="primary" onClick={handleDelete}>
                删除
              </Button>
            </Flex>
          </div>
        )}
      </div>
    </>
  );
};

export default Todolist;
