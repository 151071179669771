import React, { useState } from "react";
import html2canvas from "html2canvas";
import { Button, Input, message, Select } from "antd";

const { TextArea } = Input;
const { Option } = Select;

const InvoiceGenerator = () => {
  const [invoiceData, setInvoiceData] = useState("");
  const [currency, setCurrency] = useState("GBP"); // 默认货币为 GBP
  const [weightUnit, setWeightUnit] = useState("kg"); // 默认重量单位为 kg

  const handleDownloadInvoice = async () => {
    let data;
    try {
      data = JSON.parse(invoiceData);
    } catch (error) {
      message.error("Invalid JSON format. Please check your input.");
      return;
    }

    if (!data || !data.title) {
      message.error("Invoice data must include a title.");
      return;
    }

    message.info("Generating invoice...");

    // 根据选择的货币设置符号
    const currencySymbol = {
      GBP: "£",
      USD: "$",
      CNY: "¥",
    }[currency];

    // 根据选择的重量单位设置后缀
    const weightSuffix = weightUnit === "kg" ? "kg" : "ton";

    // Dynamically create an HTML element for the invoice
    const invoiceElement = document.createElement("div");
    invoiceElement.style.padding = "20px";
    invoiceElement.style.fontFamily = "Arial, sans-serif";

    invoiceElement.innerHTML = `
      <div style="text-align: center; margin-bottom: 20px;">
        <h1>${data.title || "Invoice"}</h1>
        <p>Invoice Number: ${data.invoiceNumber || "N/A"}</p>
        <p>Date: ${data.invoiceDate || "N/A"}</p>
      </div>
      <div>
        <h3>Export Company</h3>
        ${
          data.exportCompany
            ? data.exportCompany
                .map(
                  (company) => `
            <p>Company Name: ${company.company_name}</p>
            <p>Address: ${company.address}</p>
          `
                )
                .join("")
            : "<p>No export company information</p>"
        }
      </div>
      <div>
        <h3>Import Company</h3>
        ${
          data.importCompany
            ? data.importCompany
                .map(
                  (company) => `
            <p>Company Name: ${company.company_name}</p>
            <p>VAT: ${company.vat}</p>
            <p>EORI: ${company.eori}</p>
            <p>Address: ${company.address}</p>
          `
                )
                .join("")
            : "<p>No import company information</p>"
        }
      </div>
      <div>
        <h3>Products</h3>
        <table style="width: 100%; border-collapse: collapse;" border="1">
          <thead>
            <tr>
              <th>HS Code</th>
              <th>Description</th>
              <th>Gross Weight</th>
              <th>Net Weight</th>
              <th>Cube</th>
              <th>Material</th>
              <th>Unit Value</th>
              <th>CTNs</th>
              <th>Quantity</th>
              <th>Total Value</th>
            </tr>
          </thead>
          <tbody>
            ${
              data.importedProduct
                ? data.importedProduct
                    .map(
                      (product) => `
                <tr>
                  <td>${product.hs_code}</td>
                  <td>${product.description}</td>
                  <td>${product.grossweight} ${weightSuffix}</td>
                  <td>${product.netweight} ${weightSuffix}</td>
                  <td>${product.cube}</td>
                  <td>${product.material}</td>
                  <td>${currencySymbol}${product.unit_value}</td>
                  <td>${product.ctns}</td>
                  <td>${product.quantity}</td>
                  <td>${currencySymbol}${product.total_value}</td>
                </tr>
              `
                    )
                    .join("")
                : "<tr><td colspan='10'>No products information</td></tr>"
            }
          </tbody>
        </table>
      </div>
      <div>
        <h3>Summary</h3>
        <p>Total Value: ${currencySymbol}${data["Total Value"] || "N/A"}</p>
        <p>Total CTNs: ${data["Total CTNs"] || "N/A"}</p>
        <p>Total Gross Weight: ${data["Total Gross Weight"] || "N/A"} ${weightSuffix}</p>
        <p>Trade Terms: ${data.tradeTerms || "N/A"}</p>
        <p>Destination Port: ${data.destinationPort || "N/A"}</p>
        <p>Country of Origin: ${data.countryOfOrigin || "N/A"}</p>
        <p>Container Number: ${data.containerNo || "N/A"}</p>
      </div>
    `;

    document.body.appendChild(invoiceElement);

    // Convert the HTML to Canvas
    const canvas = await html2canvas(invoiceElement);
    const link = document.createElement("a");
    link.download = `${data.title || "invoice"}.png`;
    link.href = canvas.toDataURL("image/png");
    link.click();

    // Clean up
    document.body.removeChild(invoiceElement);
    message.success("Invoice downloaded successfully!");
  };

  return (
    <div style={{ padding: "20px" }}>
      <h1>Invoice Generator</h1>
      <TextArea
        rows={10}
        placeholder="Enter invoice data in JSON format"
        value={invoiceData}
        onChange={(e) => setInvoiceData(e.target.value)}
        style={{ marginBottom: "20px" }}
      />
      <Select
        defaultValue="GBP"
        style={{ width: 120, marginBottom: "20px", display: "block" }}
        onChange={(value) => setCurrency(value)}
      >
        <Option value="GBP">GBP (£)</Option>
        <Option value="USD">USD ($)</Option>
        <Option value="CNY">CNY (¥)</Option>
      </Select>
      <Select
        defaultValue="kg"
        style={{ width: 120, marginBottom: "20px", display: "block" }}
        onChange={(value) => setWeightUnit(value)}
      >
        <Option value="kg">kg</Option>
        <Option value="ton">ton</Option>
      </Select>
      <Button type="primary" onClick={handleDownloadInvoice}>
        Download Invoice
      </Button>
    </div>
  );
};

export default InvoiceGenerator;