import React, { useEffect, useState } from 'react';
import styles from '../style/Header.module.css';
import { NavLink, useLocation } from 'react-router-dom';
import Logout from './Logout';
import { useDispatch, useSelector } from 'react-redux';
import {
  updateShowSelectMode,
} from '../store/reducers/commonSlice';
import EditRouteDetailModal from './EditRouteDetailModal';
import {
  getRoutesList,
  deleteRouteById,
  createNewRoute,
  removeCustomerFromRouteThunk,
  getRouteDetails, updateRouteDetailsName, updateRouteDetails,
} from '../store/reducers/contactSlice';
import { Button, Dropdown, Modal, Input, List, Spin } from 'antd';
import { MenuOutlined } from '@ant-design/icons';

export default function Header(props) {
  const [menuDropdownMenus, setMenuDropdownMenus] = useState([]);
  const [isEditRouteModalVisible, setIsEditRouteModalVisible] = useState(false);
  const [isAddRouteModalVisible, setIsAddRouteModalVisible] = useState(false);
  const [isEditRouteDetailModalVisible, setIsEditRouteDetailModalVisible] = useState(false);
  const [newRouteName, setNewRouteName] = useState('');
  const [selectedRoute, setSelectedRoute] = useState(null);
  const [loadingDetails, setLoadingDetails] = useState(false); // 加载状态

  const { selecting } = useSelector((state) => state.common);
  const { routes, routeDetails } = useSelector((state) => state.contact);

  const dispatch = useDispatch();

  // Functions to handle button actions
  const onSaveRouteDetails = () => {
    dispatch(updateRouteDetails(routeDetails))
      .then(() => {
        setIsEditRouteDetailModalVisible(false);
      })
  };

  const onAddNewCustomer = () => {
    setIsEditRouteModalVisible(false);
    setIsEditRouteDetailModalVisible(false);
    dispatch(
      updateShowSelectMode({
        modeName: 'showSelectContactMode',
        show: true,
      }),
    );
  };

  const onDeleteCustomer = (customerId) => {
    // 处理删除逻辑
    console.log('删除客户 ID:', customerId);
    // 调用 API 或更新状态
    dispatch(removeCustomerFromRouteThunk({
      routeId: routeDetails.id,
      customerIds: [customerId]
    }))
  };

  const location = useLocation();

  useEffect(() => {
    dispatch(getRoutesList());
  }, [dispatch]);

  useEffect(() => {
    if (location.pathname === '/info/home') {
      setMenuDropdownMenus([
        {
          label: (
            <button
              className={styles.dropdownButton}
              onClick={() => handleShowSelectMode('showSelectGetPaidMode')}
            >
              Select GetPaid
            </button>
          ),
          key: '0',
        },
      ]);
    } else if (location.pathname === '/info/contacts') {
      setMenuDropdownMenus([
        {
          label: (
            <button
              className={styles.dropdownButton}
              onClick={() => handleShowSelectMode('showSelectContactMode')}
            >
              选择Select Contact
            </button>
          ),
          key: '0',
        },
        {
          label: (
            <button
              className={styles.dropdownButton}
              onClick={() => setIsEditRouteModalVisible(true)}
            >
              编辑路线Edit Route
            </button>
          ),
          key: '1',
        },
      ]);
    } else if (location.pathname === '/info/products') {
      setMenuDropdownMenus([
        {
          label: (
            <button
              className={styles.dropdownButton}
              onClick={() => handleShowSelectMode('showSelectProductMode')}
            >
              Select Product
            </button>
          ),
          key: '0',
        },
      ]);
    } else {
      setMenuDropdownMenus([]);
    }
  }, [location]);

  const handleShowSelectMode = (modeName) => {
    dispatch(
      updateShowSelectMode({
        modeName,
        show: true,
      })
    );
  };

  const handleAddRoute = () => {
    if (!newRouteName.trim()) {
      Modal.error({ title: 'Error', content: '请输入路线名称' });
      return;
    }
    dispatch(createNewRoute({ name: newRouteName }))
      .then(() => {
        Modal.success({ title: '成功', content: '新增路线成功' });
        dispatch(getRoutesList());
      })
      .catch(() => {
        Modal.error({ title: '错误', content: '新增路线失败' });
      });
    setNewRouteName('');
    setIsAddRouteModalVisible(false);
  };

  const handleDeleteRoute = (routeId) => {
    dispatch(deleteRouteById(routeId))
      .then(() => {
        Modal.success({ title: '成功', content: '删除路线成功' });
        dispatch(getRoutesList());
      })
      .catch(() => {
        Modal.error({ title: '错误', content: '删除路线失败' });
      });
  };

  const handleEditRoute = (route) => {
    setSelectedRoute(route);
    setLoadingDetails(true);
    setIsEditRouteDetailModalVisible(true);
    dispatch(getRouteDetails(route.id))
      .then((response) => {
        setLoadingDetails(false);
      })
      .catch(() => {
        Modal.error({ title: '错误', content: '加载路线详情失败' });
        setLoadingDetails(false);
      });
  };

  const onRouteNameChange = (e) => {
    dispatch(updateRouteDetailsName(e.target.value))
  }

  return (
    <div>
      {!selecting && (
        <>
          <div className={styles.header}>
            <div className={styles.headerLeft}>
              <NavLink activeClassName={styles.active} to={'/info/home'}>
                <div>GetPaid</div>
              </NavLink>
              <NavLink activeClassName={styles.active} to={'/info/custom'}>
                <div>Custom</div>
              </NavLink>
              <NavLink activeClassName={styles.active} to={'/info/contacts'}>
                <div>Contacts</div>
              </NavLink>
              <NavLink activeClassName={styles.active} to={'/info/products'}>
                <div>Products</div>
              </NavLink>
            </div>
            <div className={styles.headerRight}>
              <Logout></Logout>
            </div>
          </div>
          {['/info/home', '/info/stock', '/info/contacts', '/info/products'].includes(
            location.pathname
          ) && (
            <Dropdown menu={{ items: menuDropdownMenus }} trigger={['click']}>
              <Button
                className={styles.headerBtn}
                shape="circle"
                icon={<MenuOutlined />}
              />
            </Dropdown>
          )}
        </>
      )}
      {props.children}

      {/* Edit Route Modal */}
      <Modal
        title="编辑路线"
        visible={isEditRouteModalVisible}
        onCancel={() => setIsEditRouteModalVisible(false)}
        footer={[
          <Button
            key="add"
            type="primary"
            onClick={() => setIsAddRouteModalVisible(true)}
          >
            新增路线
          </Button>,
        ]}
      >
        <List
          dataSource={routes}
          renderItem={(route) => (
            <List.Item
              actions={[
                <Button
                  type="link"
                  onClick={() => handleEditRoute(route)}
                  key="edit"
                >
                  编辑
                </Button>,
                <Button
                  type="link"
                  danger
                  onClick={() => handleDeleteRoute(route.id)}
                  key="delete"
                >
                  删除
                </Button>,
              ]}
            >
              {route.name}
            </List.Item>
          )}
        />
      </Modal>

      {/* Add Route Modal */}
      <Modal
        title="新增路线"
        visible={isAddRouteModalVisible}
        onCancel={() => setIsAddRouteModalVisible(false)}
        onOk={handleAddRoute}
      >
        <Input
          value={newRouteName}
          onChange={(e) => setNewRouteName(e.target.value)}
          placeholder="请输入路线名称"
        />
      </Modal>

      {/* Edit Route Detail Modal */}

      <Modal
        title={
          <div>
            <h3>编辑路线详情</h3>
            <div style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
              <span>路线名称：</span>
              <Input
                placeholder="请输入路线名称"
                value={routeDetails?.name || ''}
                onChange={onRouteNameChange}
              />
            </div>
          </div>
        }
        visible={isEditRouteDetailModalVisible}
        onCancel={() => setIsEditRouteDetailModalVisible(false)}
        footer={
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <Button type="primary" onClick={onSaveRouteDetails}>
              保存
            </Button>
            <Button type="default" onClick={onAddNewCustomer}>
              添加新客户
            </Button>
          </div>
        }
      >
        {loadingDetails ? (
          <Spin />
        ) : routeDetails ? (
          <List
            dataSource={routeDetails.customers}
            renderItem={({ customer }) => (
              <List.Item
                actions={[
                  <Button
                    type="link"
                    danger
                    onClick={() => onDeleteCustomer(customer.id)}
                  >
                    删除
                  </Button>,
                ]}
              >
                <div>
                  <b>{customer.customer_name}</b>
                  <p>{customer.customer_address}</p>
                  <p>{customer.city}</p>
                  <p>{customer.phone}</p>
                </div>
              </List.Item>
            )}
          />
        ) : (
          <p>无客户数据</p>
        )}
      </Modal>


    </div>
  );
}
