import { configureStore } from '@reduxjs/toolkit';
import commonReducer from './reducers/commonSlice';
import userReducer from './reducers/userSlice';
import invoiceReducer from './reducers/invoiceSlice';
import contactReducer from './reducers/contactSlice';
import productReducer from './reducers/productSlice';
import supplierContactReducer from './reducers/supplierContactSlice';

export default configureStore({
  reducer: {
    common: commonReducer,
    user: userReducer,
    invoice: invoiceReducer,
    contact: contactReducer,
    product: productReducer,
    supplierContact: supplierContactReducer,
  },
});
