import React, { useEffect, useState } from 'react';
import styles from '../style/Footer.module.css';
import { NavLink, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { updateShowSelectMode } from '../store/reducers/commonSlice';
import { Button, Dropdown, Modal, Input, List, Spin } from 'antd';
import { MenuOutlined } from '@ant-design/icons';

// 导入 supplier 版的 action（注意路径根据实际情况调整）
import {
  getSupplierRoutesList,
  createSupplierRoute,
  deleteSupplierRouteById,
  getSupplierRouteDetails,
  updateSupplierRouteDetails,
  updateSupplierRouteDetailsName,
  updateSupplierShowSelectContactMode, removeSupplierCustomerFromRouteThunk,
  // removeSupplierCustomerFromRouteThunk, // 如需删除供应商客户，可引入此 thunk
} from '../store/reducers/supplierContactSlice';

export default function Footer() {
  const [menuDropdownMenus, setMenuDropdownMenus] = useState([]);
  const { selecting } = useSelector((state) => state.common);
  const location = useLocation();
  const dispatch = useDispatch();

  // 定义供应商路线相关的本地状态（仅用于 /info/todolist）
  const [isEditSupplierRouteModalVisible, setIsEditSupplierRouteModalVisible] = useState(false);
  const [isAddSupplierRouteModalVisible, setIsAddSupplierRouteModalVisible] = useState(false);
  const [isEditSupplierRouteDetailModalVisible, setIsEditSupplierRouteDetailModalVisible] = useState(false);
  const [newSupplierRouteName, setNewSupplierRouteName] = useState('');
  const [selectedSupplierRoute, setSelectedSupplierRoute] = useState(null);
  const [loadingSupplierDetails, setLoadingSupplierDetails] = useState(false);

  // 从 supplierContactSlice 中获取供应商路线和路线详情数据
  const { routes, routeDetails } = useSelector((state) => state.supplierContact);

  useEffect(() => {
    if (location.pathname === '/info/futurehome') {
      setMenuDropdownMenus([
        {
          label: (
            <button
              className={styles.dropdownButton}
              onClick={() => handleShowSelectMode('showSelectScheduleMode')}
            >
              Select Schedule
            </button>
          ),
          key: '0',
        },
      ]);
    } else if (location.pathname === '/info/notpaidhome') {
      setMenuDropdownMenus([
        {
          label: (
            <button
              className={styles.dropdownButton}
              onClick={() => handleShowSelectMode('showSelectCreditMode')}
            >
              Select Credit
            </button>
          ),
          key: '0',
        },
      ]);
    } else if (location.pathname === '/info/todolist') {
      // 在 TodoList 路由下，显示供应商版的专属菜单项
      setMenuDropdownMenus([
        {
          label: (
            <button
              className={styles.dropdownButton}
              onClick={() => handleShowSelectMode('showSelectTodoListMode')}
            >
              Select tasks选择任务
            </button>
          ),
          key: '0',
        },
        {
          label: (
            <button
              className={styles.dropdownButton}
              onClick={() => setIsEditSupplierRouteModalVisible(true)}
            >
              编辑任务分类
            </button>
          ),
          key: '1',
        },
      ]);
      // 可在此处主动获取供应商路线数据
      dispatch(getSupplierRoutesList());
    } else if (location.pathname === '/info/historyhome') {
      setMenuDropdownMenus([
        {
          label: (
            <button
              className={styles.dropdownButton}
              onClick={() => handleShowSelectMode('showSelectHistoryMode')}
            >
              Select History
            </button>
          ),
          key: '0',
        },
      ]);
    } else {
      setMenuDropdownMenus([]);
    }
  }, [location, dispatch]);

  // 通用的选择模式（用于 futurehome、notpaidhome、historyhome 等）
  const handleShowSelectMode = (modeName) => {
    dispatch(
      updateShowSelectMode({
        modeName,
        show: true,
      })
    );
  };

  // 供应商路线新增
  const handleAddSupplierRoute = () => {
    if (!newSupplierRouteName.trim()) {
      Modal.error({ title: '错误', content: '请输入供应商路线名称' });
      return;
    }
    dispatch(createSupplierRoute({ name: newSupplierRouteName }))
      .then(() => {
        Modal.success({ title: '成功', content: '新增供应商路线成功' });
        dispatch(getSupplierRoutesList());
      })
      .catch(() => {
        Modal.error({ title: '错误', content: '新增供应商路线失败' });
      });
    setNewSupplierRouteName('');
    setIsAddSupplierRouteModalVisible(false);
  };

  // 供应商路线删除
  const handleDeleteSupplierRoute = (routeId) => {
    dispatch(deleteSupplierRouteById(routeId))
      .then(() => {
        Modal.success({ title: '成功', content: '删除供应商路线成功' });
        dispatch(getSupplierRoutesList());
      })
      .catch(() => {
        Modal.error({ title: '错误', content: '删除供应商路线失败' });
      });
  };

  // 进入供应商路线编辑流程：打开编辑详情模态框，并获取该路线详情数据
  const handleEditSupplierRoute = (route) => {
    setSelectedSupplierRoute(route);
    setLoadingSupplierDetails(true);
    setIsEditSupplierRouteDetailModalVisible(true);
    dispatch(getSupplierRouteDetails(route.id))
      .then(() => {
        setLoadingSupplierDetails(false);
      })
      .catch(() => {
        Modal.error({ title: '错误', content: '加载供应商路线详情失败' });
        setLoadingSupplierDetails(false);
      });
  };

  // 当在供应商路线详情中修改名称时
  const onSupplierRouteNameChange = (e) => {
    dispatch(updateSupplierRouteDetailsName(e.target.value));
  };

  // 保存供应商路线详情
  const onSaveSupplierRouteDetails = () => {
    dispatch(updateSupplierRouteDetails(routeDetails))
      .then(() => {
        setIsEditSupplierRouteDetailModalVisible(false);
      });
  };

  // 点击“添加新供应商联系人”，关闭编辑模态框并进入联系人选择模式
  const onAddNewSupplier = () => {
    setIsEditSupplierRouteModalVisible(false);
    setIsEditSupplierRouteDetailModalVisible(false);
    dispatch(updateSupplierShowSelectContactMode(true));
  };

  // 删除供应商路线下某个客户（逻辑可根据需求扩展）
  const onDeleteSupplierCustomer = (customerId) => {
    console.log('删除供应商客户 ID:', customerId);
    // 如有需要，可 dispatch 对应的 removeSupplierCustomerFromRouteThunk action
    dispatch(removeSupplierCustomerFromRouteThunk({
      routeId: routeDetails.id,
      customerIds: [customerId]
    }))
  };

  return (
    <>
      {!selecting && (
        <>
          <div className={styles.footer}>
            <div className={styles.footerNav}>
              <div>
                <NavLink activeClassName={styles.active} to={'/info/futurehome'}>
                  <div>Schedule</div>
                </NavLink>
              </div>
              <div>
                <NavLink activeClassName={styles.active} to={'/info/notpaidhome'}>
                  <div>Credit</div>
                </NavLink>
              </div>
              <div>
                <NavLink activeClassName={styles.active} to={'/info/todolist'}>
                  <div>TodoList</div>
                </NavLink>
              </div>
              <div>
                <NavLink activeClassName={styles.active} to={'/info/historyhome'}>
                  <div>History</div>
                </NavLink>
              </div>
            </div>
          </div>
          {(['/info/futurehome', '/info/notpaidhome', '/info/todolist', '/info/historyhome'].includes(location.pathname)) && (
            <Dropdown menu={{ items: menuDropdownMenus }} trigger={['click']} placement="topLeft">
              <Button className={styles.footerBtn} shape="circle" icon={<MenuOutlined />} />
            </Dropdown>
          )}

          {/* 仅在 /info/todolist 路由下显示供应商路线管理相关的模态框 */}
          {location.pathname === '/info/todolist' && (
            <>
              {/* 编辑供应商路线模态框 */}
              <Modal
                title="编辑任务分类"
                visible={isEditSupplierRouteModalVisible}
                onCancel={() => setIsEditSupplierRouteModalVisible(false)}
                footer={[
                  <Button key="add" type="primary" onClick={() => setIsAddSupplierRouteModalVisible(true)}>
                    新增任务分类
                  </Button>,
                ]}
              >
                <List
                  dataSource={routes}
                  renderItem={(route) => (
                    <List.Item
                      actions={[
                        <Button type="link" onClick={() => handleEditSupplierRoute(route)} key="edit">
                          编辑
                        </Button>,
                        <Button type="link" danger onClick={() => handleDeleteSupplierRoute(route.id)} key="delete">
                          删除
                        </Button>,
                      ]}
                    >
                      {route.name}
                    </List.Item>
                  )}
                />
              </Modal>

              {/* 新增供应商路线模态框 */}
              <Modal
                title="新增供应商路线"
                visible={isAddSupplierRouteModalVisible}
                onCancel={() => setIsAddSupplierRouteModalVisible(false)}
                onOk={handleAddSupplierRoute}
              >
                <Input
                  value={newSupplierRouteName}
                  onChange={(e) => setNewSupplierRouteName(e.target.value)}
                  placeholder="请输入供应商路线名称"
                />
              </Modal>

              {/* 编辑供应商路线详情模态框 */}
              <Modal
                title={
                  <div>
                    <h3>编辑任务分类详情</h3>
                    <div style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
                      <span>任务分类名称：</span>
                      <Input
                        placeholder="请输入任务分类名称"
                        value={routeDetails?.name || ''}
                        onChange={onSupplierRouteNameChange}
                      />
                    </div>
                  </div>
                }
                visible={isEditSupplierRouteDetailModalVisible}
                onCancel={() => setIsEditSupplierRouteDetailModalVisible(false)}
                footer={
                  <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                    <Button type="primary" onClick={onSaveSupplierRouteDetails}>
                      保存
                    </Button>
                    <Button type="default" onClick={onAddNewSupplier}>
                      添加新任务
                    </Button>
                  </div>
                }
              >
                {loadingSupplierDetails ? (
                  <Spin />
                ) : routeDetails ? (
                  <List
                    dataSource={routeDetails.customers}
                    renderItem={({ customer }) => (
                      <List.Item
                        actions={[
                          <Button type="link" danger onClick={() => onDeleteSupplierCustomer(customer.id)}>
                            删除
                          </Button>,
                        ]}
                      >
                        <div>
                          <b>{customer.customer_name}</b>
                          <p>{customer.customer_address}</p>
                          <p>{customer.city}</p>
                          <p>{customer.phone}</p>
                        </div>
                      </List.Item>
                    )}
                  />
                ) : (
                  <p>无供应商联系人数据</p>
                )}
              </Modal>
            </>
          )}
        </>
      )}
    </>
  );
}
