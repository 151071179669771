import React, { useState } from 'react';
import { Button, Input, Modal, Form, message } from 'antd';
import { useHistory } from 'react-router-dom'; // 引入 useHistory

const fetchJsonData = async (url) => {
  try {
    const response = await fetch(url);
    if (!response.ok) throw new Error('Failed to fetch data');
    return await response.json();
  } catch (error) {
    message.error(error.message);
    return [];
  }
};

const Custom = () => {
  const [form] = Form.useForm();
  const history = useHistory(); // 使用 useHistory 钩子

  const [exportModalVisible, setExportModalVisible] = useState(false);
  const [importModalVisible, setImportModalVisible] = useState(false);
  const [productModalVisible, setProductModalVisible] = useState(false);

  const [exportData, setExportData] = useState(null);
  const [importData, setImportData] = useState(null);
  const [productData, setProductData] = useState(null);

  const copyToClipboard = (data) => {
    navigator.clipboard.writeText(JSON.stringify(data, null, 2));
    message.success('Copied to clipboard!');
  };

  const openModal = async (type) => {
    let url = '';
    if (type === 'export') url = 'https://www.butt-and-co.co.uk/api/export-companies/';
    if (type === 'import') url = 'https://www.butt-and-co.co.uk/api/import-companies/';
    if (type === 'product') url = 'https://www.butt-and-co.co.uk/api/imported-products/';

    const data = await fetchJsonData(url);
    if (type === 'export') {
      setExportData(data);
      setExportModalVisible(true);
    }
    if (type === 'import') {
      setImportData(data);
      setImportModalVisible(true);
    }
    if (type === 'product') {
      setProductData(data);
      setProductModalVisible(true);
    }
  };

  const handleGenerateInvoice = async (values) => {
    try {
      // 验证 JSON 字段
      const validateJsonField = (fieldName, value) => {
        try {
          if (value) JSON.parse(value); // 尝试解析 JSON
          return true;
        } catch (error) {
          message.error(`${fieldName} is not valid JSON`);
          return false;
        }
      };

      if (
        !validateJsonField('Export Company', values.exportCompany) ||
        !validateJsonField('Import Company', values.importCompany) ||
        !validateJsonField('Imported Product', values.importedProduct)
      ) {
        return; // 如果 JSON 无效，停止提交
      }

      // 将 JSON 字符串解析为对象
      const parsedValues = {
        ...values,
        exportCompany: values.exportCompany ? JSON.parse(values.exportCompany) : null,
        importCompany: values.importCompany ? JSON.parse(values.importCompany) : null,
        importedProduct: values.importedProduct ? JSON.parse(values.importedProduct) : null,
      };

      const response = await fetch('https://www.butt-and-co.co.uk/api/customdata', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(parsedValues),
      });

      if (!response.ok) throw new Error('Failed to generate invoice');

      const result = await response.json();

      // 将响应 JSON 字典复制到剪贴板
      navigator.clipboard.writeText(JSON.stringify(result, null, 2));
      message.success('Invoice generated successfully and copied to clipboard!');
      console.log('Response:', result); // 如果需要可以打印响应结果
    } catch (error) {
      message.error(error.message);
    }
  };

  return (
    <div style={{ padding: '80px 16px', minHeight: '100vh', overflowY: 'auto' }}>
      <div style={{ maxWidth: '600px', margin: '0 auto' }}>
        <Button block onClick={() => openModal('export')}>
          Export Company
        </Button>

        <Modal
          title="Export Companies"
          visible={exportModalVisible}
          footer={null}
          onCancel={() => setExportModalVisible(false)}
        >
          <pre>{JSON.stringify(exportData, null, 2)}</pre>
          <Button block onClick={() => copyToClipboard(exportData)}>
            Copy JSON
          </Button>
        </Modal>

        <Button block style={{ marginTop: '16px' }} onClick={() => openModal('import')}>
          Import Company
        </Button>

        <Modal
          title="Import Companies"
          visible={importModalVisible}
          footer={null}
          onCancel={() => setImportModalVisible(false)}
        >
          <pre>{JSON.stringify(importData, null, 2)}</pre>
          <Button block onClick={() => copyToClipboard(importData)}>
            Copy JSON
          </Button>
        </Modal>

        <Button block style={{ marginTop: '16px' }} onClick={() => openModal('product')}>
          Imported Products
        </Button>

        <Modal
          title="Imported Products"
          visible={productModalVisible}
          footer={null}
          onCancel={() => setProductModalVisible(false)}
        >
          <pre>{JSON.stringify(productData, null, 2)}</pre>
          <Button block onClick={() => copyToClipboard(productData)}>
            Copy JSON
          </Button>
        </Modal>

        <Form form={form} layout="vertical" style={{ marginTop: '24px' }} onFinish={handleGenerateInvoice}>
          <Form.Item label="Destination Port" name="destinationPort" initialValue="Felixtowe">
            <Input placeholder="Enter Destination Port" />
          </Form.Item>
          <Form.Item label="Country of Origin" name="countryOfOrigin" initialValue="China">
            <Input placeholder="Enter Country of Origin" />
          </Form.Item>
          <Form.Item label="Invoice Date" name="invoiceDate">
            <Input type="date" />
          </Form.Item>
          <Form.Item label="Invoice Number" name="invoiceNumber">
            <Input placeholder="Enter Invoice Number" />
          </Form.Item>
          <Form.Item label="Container No." name="containerNo">
            <Input placeholder="Enter Container No." />
          </Form.Item>
          <Form.Item label="Trade Terms" name="tradeTerms" initialValue="FOB">
            <Input placeholder="Enter Trade Terms" />
          </Form.Item>
          <Form.Item
            label="Export Company"
            name="exportCompany"
            rules={[
              {
                validator: (_, value) => {
                  if (!value) return Promise.resolve(); // 允许为空
                  try {
                    JSON.parse(value); // 验证是否为有效 JSON
                    return Promise.resolve();
                  } catch (error) {
                    return Promise.reject('Please enter valid JSON');
                  }
                },
              },
            ]}
          >
            <Input.TextArea placeholder="Enter Export Company as JSON" rows={4} />
          </Form.Item>
          <Form.Item
            label="Import Company"
            name="importCompany"
            rules={[
              {
                validator: (_, value) => {
                  if (!value) return Promise.resolve(); // 允许为空
                  try {
                    JSON.parse(value); // 验证是否为有效 JSON
                    return Promise.resolve();
                  } catch (error) {
                    return Promise.reject('Please enter valid JSON');
                  }
                },
              },
            ]}
          >
            <Input.TextArea placeholder="Enter Import Company as JSON" rows={4} />
          </Form.Item>
          <Form.Item
            label="Imported Product"
            name="importedProduct"
            rules={[
              {
                validator: (_, value) => {
                  if (!value) return Promise.resolve(); // 允许为空
                  try {
                    JSON.parse(value); // 验证是否为有效 JSON
                    return Promise.resolve();
                  } catch (error) {
                    return Promise.reject('Please enter valid JSON');
                  }
                },
              },
            ]}
          >
            <Input.TextArea placeholder="Enter Imported Product as JSON" rows={4} />
          </Form.Item>
          <Button block type="primary" htmlType="submit" style={{ marginTop: '24px' }}>
            获取json
          </Button>
        </Form>

        {/* 添加跳转到发票页面的按钮 */}
        <Button block type="primary" style={{ marginTop: '24px' }} onClick={() => history.push('/info/InvoiceGenerator')}>
          跳转到发票页面
        </Button>
        {/* 添加跳转到发票页面的按钮 */}
        <Button block type="primary" style={{ marginTop: '24px' }} onClick={() => history.push('/info/ProductForm')}>
          跳转到产品录入页面
        </Button>
      </div>
    </div>
  );
};

export default Custom;
