import React, { useEffect, useRef } from 'react';
import { Button, Form, Input, Select } from 'antd';
import styles from '../style/ContactFormNew.module.css';
const { TextArea } = Input;
const { Option } = Select;

const ContactFormNew = ({ addNewContact, onClose, contact, updateContact }) => {
  const formRef = useRef();

  useEffect(() => {
    if (contact && formRef.current) {
      formRef.current.setFieldsValue(contact);
    }
  }, [contact]);

  const onSubmit = (values) => {
    const { email, customer_name, customer_address, city, postcode, phone } = values;

    if (contact) {
      updateContact(
        customer_name,
        email,
        contact.id,
        customer_address,
        city,
        postcode,
        phone,
      );
    } else {
      addNewContact(
        customer_name,
        email,
        customer_address,
        city,
        postcode,
        phone,
      );
    }

    onClose();
  };

  return (
    <div>
      <Form
        ref={formRef}
        name="contact"
        layout="vertical"
        initialValues={{ email: 'default@gmail.com', ...contact }}
        onFinish={onSubmit}
      >
        <Form.Item
          name="customer_name"
          label="task name"
          rules={[
            {
              required: true,
              message: 'Please input task name!',
            },
          ]}
        >
          <Input />
        </Form.Item>

        {/* 修改这里：把优先级从 Input 改为 Select */}
        <Form.Item
          name="city"
          label="优先级"
          rules={[
            {
              required: true,
              message: 'Please select 优先级!',
            },
          ]}
        >
          <Select placeholder="请选择优先级">
            <Option value="高">高</Option>
            <Option value="中">中</Option>
            <Option value="低">低</Option>
          </Select>
        </Form.Item>

        <Form.Item
          name="postcode"
          label="地点"
          rules={[
            {
              required: true,
              message: 'Please input 地点!',
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          name="phone"
          label="截止日期"
          rules={[
            {
              required: true,
              message: 'Please input 截止日期!',
            },
          ]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          name="email"
          hidden
          rules={[
            {
              required: true,
              message: 'Please input email address!',
            },
            {
              pattern: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
              message: 'Please input a valid email!',
            },
          ]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          name="customer_address"
          label="task description"
          rules={[
            {
              required: true,
              message: 'Please input task description!',
            },
          ]}
        >
          <TextArea rows={4} />
        </Form.Item>

        <Form.Item>
          <Button
            block
            type="primary"
            htmlType="submit"
            className={styles.submitBtn}
          >
            {contact ? 'Update Contact' : 'Add Contact'}
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
};

export default ContactFormNew;
