import { getUserInfo } from './user';
import axios from 'axios';

const REACT_APP_SERVER = process.env.REACT_APP_SERVER;

export const addContactOnServer = async (obj, thunkAPI) => {
  try {
    const { customer_name, email, customer_address, city, postcode, phone, images } =
      obj;
    const userInfo = getUserInfo();
    const { data } = await axios.post(
      `${REACT_APP_SERVER}/create-driver-customer/`,
      {
        customer_name,
        email,
        ...userInfo,
        customer_address,
        city,
        postcode,
        images, // 新增的字段，传入图片链接数组。如果没有图片，可以传空数组或忽略（后端应有默认值）
        phone,
      },
    );
    return data;
  } catch (error) {
    return thunkAPI.rejectWithValue(error.message);
  }
};

export const getAllContacts = async (thunkAPI) => {
  try {
    const userInfo = getUserInfo();
    const { data } = await axios.get(
      `${REACT_APP_SERVER}/api/responsible_persons/${userInfo.id}/customers/`,
    );
    return data;
  } catch (error) {
    return thunkAPI.rejectWithValue(error.message);
  }
};

export const getAllRoutes = async (thunkAPI) => {
  try {
    const userInfo = getUserInfo();
    const { data } = await axios.get(
      `${REACT_APP_SERVER}/routeapi/responsible-persons/${userInfo.id}/routes/`,
    );
    return data;
  } catch (error) {
    return thunkAPI.rejectWithValue(error.message);
  }
};

// 创建新路线
export const createRoute = async (routeData, thunkAPI) => {
  try {
    const userInfo = getUserInfo();
    const { data } = await axios.post(
      `${REACT_APP_SERVER}/routeapi/responsible-persons/${userInfo.id}/routes/`,
      routeData,
    );
    return data;
  } catch (error) {
    return thunkAPI.rejectWithValue(error.message);
  }
};

// 查询指定路线的详细信息
export const getRouteById = async (routeId, thunkAPI) => {
  try {
    const userInfo = getUserInfo();
    const { data } = await axios.get(
      `${REACT_APP_SERVER}/routeapi/responsible-persons/${userInfo.id}/routes/${routeId}/`,
    );
    return data;
  } catch (error) {
    return thunkAPI.rejectWithValue(error.message);
  }
};

// 更新指定路线名称
export const updateRoute = async (routeId, updatedData, thunkAPI) => {
  try {
    const userInfo = getUserInfo();
    const { data } = await axios.put(
      `${REACT_APP_SERVER}/routeapi/responsible-persons/${userInfo.id}/routes/${routeId}/`,
      updatedData,
    );
    return data;
  } catch (error) {
    return thunkAPI.rejectWithValue(error.message);
  }
};

// 删除指定路线
export const deleteRoute = async (routeId, thunkAPI) => {
  try {
    const userInfo = getUserInfo();
    const { data } = await axios.delete(
      `${REACT_APP_SERVER}/routeapi/responsible-persons/${userInfo.id}/routes/${routeId}/`,
    );
    return data;
  } catch (error) {
    return thunkAPI.rejectWithValue(error.message);
  }
};

// 将客户添加到指定路线
export const addCustomerToRoute = async (routeId, customerIds, thunkAPI) => {
  try {
    const userInfo = getUserInfo();
    const { data } = await axios.post(
      `${REACT_APP_SERVER}/routeapi/responsible-persons/${userInfo.id}/routes/${routeId}/add_customer/`,
      { customer_ids: customerIds },
    );
    return data;
  } catch (error) {
    return thunkAPI.rejectWithValue(error.message);
  }
};

// 从路线中移除客户
export const removeCustomerFromRoute = async (routeId, customerIds, thunkAPI) => {
  try {
    const userInfo = getUserInfo();
    const { data } = await axios.post(
      `${REACT_APP_SERVER}/routeapi/responsible-persons/${userInfo.id}/routes/${routeId}/remove_customer/`,
      { customer_ids: customerIds },
    );
    return data;
  } catch (error) {
    return thunkAPI.rejectWithValue(error.message);
  }
};

// 查询某一路线的所有客户
export const getRouteCustomers = async (routeId, thunkAPI) => {
  try {
    const userInfo = getUserInfo();
    const { data } = await axios.get(
      `${REACT_APP_SERVER}/routeapi/responsible-persons/${userInfo.id}/routes/${routeId}/customers/`,
    );
    return data;
  } catch (error) {
    return thunkAPI.rejectWithValue(error.message);
  }
};
export const updateContactOnServer = async (obj, thunkAPI) => {
  try {
    const {
      customer_name,
      email,
      id,
      customer_address,
      city,
      postcode,
      images, // 同样传入图片链接数组
      phone,
    } = obj;
    const userInfo = getUserInfo();
    const { data } = await axios.patch(
      `${REACT_APP_SERVER}/api/responsible_persons_edit/${userInfo.id}/customers/${id}/`,
      {
        customer_name,
        email,
        ...userInfo,
        customer_address,
        city,
        postcode,
        images,
        phone,
      },
    );
    return data;
  } catch (error) {
    return thunkAPI.rejectWithValue(error.message);
  }
};

export const deleteContactOnServer = async (id, thunkAPI) => {
  try {
    const userInfo = getUserInfo();
    const { data } = await axios.delete(
      `${REACT_APP_SERVER}/api/responsible_persons_edit/${userInfo.id}/customers/${id}/`,
    );
    return data;
  } catch (error) {
    return thunkAPI.rejectWithValue(error.message);
  }
};

export const deleteContactsOnServer = async (ids, thunkAPI) => {
  try {
    const userInfo = getUserInfo();
    const { data } = await axios.delete(
      `${REACT_APP_SERVER}/api/responsible_persons_edit/${userInfo.id}/customers/bulk_delete/`,
      {
        data: {
          customer_ids: ids
        }
      },
    );
    return data;
  } catch (error) {
    return thunkAPI.rejectWithValue(error.message);
  }
};

export const getContactById = async (id, thunkAPI) => {
  try {
    const userInfo = getUserInfo();
    const { data } = await axios.get(
      `${REACT_APP_SERVER}/api/responsible_persons_edit/${userInfo.id}/customers/${id}/`,
    );
    return data;
  } catch (error) {
    return thunkAPI.rejectWithValue(error.message);
  }
};

export const updateCustomer = async (obj, thunkAPI) => {
  try {
    const { data } = await axios.post(
      `${REACT_APP_SERVER}/api/user/update_customer_laokehu/`,
      obj,
    );
    return data;
  } catch (error) {
    return thunkAPI.rejectWithValue(error.message);
  }
};
