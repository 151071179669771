import React, { useEffect, useState } from 'react';
import ContactCard from '../components/ContactCard';
import ContactForm from '../components/ContactForm';
import KModal from '../components/KModal';
import SavedContactsForm from '../components/SavedContactsForm';
import { Button, Input, Flex, Typography, Select, Modal, Empty, message } from 'antd';
import {
  ExclamationCircleFilled,
  PlusOutlined,
  SearchOutlined,
} from '@ant-design/icons';
import styles from '../style/Contact.module.css';
import { useDispatch, useSelector } from 'react-redux';
import {
  createContact,
  deleteContact,
  getContactsList,
  getRoutesList, // 获取路线列表
  updateRouteDetails, // 路线重命名
  deleteRouteById, // 删除指定路线
  createNewRoute,  // 添加新路线
  getRouteDetails, // 获取指定路线详细信息以及所有客户
  updateContact,
  updateContactId,
  updateEditOpen,
  updateOpen,
  updateSearchData,
  updateSelectedContacts,
  updateSelectedRoute,
  updateShowSelectRoutesModal,
  updateSelectedRouteForCustomers,
  addCustomerToRouteThunk,
  deleteContacts,
  updateShowViewModal,
  updateCustomerInvoicesLoading,
} from '../store/reducers/contactSlice';
import { updateShowSelectMode } from '../store/reducers/commonSlice';
import { deleteProduct } from '../store/reducers/productSlice';
import { getInvoiceListByCustomer } from '../store/reducers/invoiceSlice';
import ViewCustomerInvoicesModal from '../components/ViewCustomerInvoicesModal';

const { confirm } = Modal;
const { Title } = Typography;
const { Option } = Select;

const Contacts = () => {
  const {
    contacts,
    contactId,
    editOpen,
    open,
    searchData,
    selectedContacts,
    routes,
    selectedRoute,
    showSelectRoutesModal,
    selectedRouteForCustomers,
    showViewModal,
  } = useSelector((state) => state.contact);
  const { showSelectContactMode } = useSelector((state) => state.common);

  const dispatch = useDispatch();
  const [sortOrder, setSortOrder] = useState('');

  useEffect(() => {
    dispatch(getContactsList());
    dispatch(getRoutesList());
  }, [dispatch]);

  useEffect(() => {
    if (selectedRoute) {
      dispatch(getRouteDetails(selectedRoute));
    } else {
      dispatch(getContactsList());
    }
  }, [selectedRoute, dispatch]);

  // 新增联系人时增加 images 参数
  const addNewContact = async (
    customer_name,
    email,
    customer_address,
    city,
    postcode,
    phone,
    images // 新增参数
  ) => {
    dispatch(
      createContact({
        customer_name,
        email,
        customer_address,
        city,
        postcode,
        phone,
        images, // 传入图片链接数组（可为空数组）
      }),
    );
  };

  // 根据搜索条件过滤联系人
  let searchContacts = contacts.filter((contact) =>
    contact.customer_name.toLowerCase().includes(searchData.toLowerCase()),
  );

  // 排序逻辑
  if (sortOrder === 'asc') {
    searchContacts = searchContacts.sort((a, b) =>
      a.customer_name.localeCompare(b.customer_name),
    );
  } else if (sortOrder === 'desc') {
    searchContacts = searchContacts.sort((a, b) =>
      b.customer_name.localeCompare(a.customer_name),
    );
  }

  // 更新联系人时增加 images 参数
  const _updateContact = async (
    customer_name,
    email,
    id,
    customer_address,
    city,
    postcode,
    phone,
    images // 新增参数
  ) => {
    dispatch(
      updateContact({
        customer_name,
        email,
        id,
        customer_address,
        city,
        postcode,
        phone,
        images,
      }),
    );
  };

  const _deleteContact = (id) => {
    confirm({
      title: 'Delete confirm',
      icon: <ExclamationCircleFilled />,
      content: '是否删除？Confirm deleting?',
      async onOk() {
        dispatch(deleteContact(id));
      },
      onCancel() {
        console.log('Cancel');
      },
    });
  };

  const _openEditContact = (id) => {
    dispatch(updateContactId(id));
    dispatch(updateEditOpen(true));
  };

  const handleSelectAll = () => {
    dispatch(updateSelectedContacts(searchContacts));
  };

  const handleSelectChange = (value) => {
    if (selectedContacts.includes(value)) {
      dispatch(updateSelectedContacts(selectedContacts.filter(contact => contact.id !== value.id)))
    } else {
      dispatch(updateSelectedContacts([...selectedContacts, value]))
    }
  };

  const handleSelectCancel = () => {
    dispatch(updateSelectedContacts([]));
    dispatch(
      updateShowSelectMode({
        modeName: 'showSelectContactMode',
        show: false,
      }),
    );
  };

  const handleRouteSelected = (value) => {
    dispatch(updateSelectedRoute(value));
  };

  const openSelectRouteModal = () => {
    dispatch(updateShowSelectRoutesModal(true));
  };

  const handleRouteSelectedForCustomers = (value) => {
    dispatch(updateSelectedRouteForCustomers(value));
  };

  const handleAddCustomersToRoute = () => {
    if (selectedRouteForCustomers) {
      dispatch(addCustomerToRouteThunk({
        routeId: selectedRouteForCustomers,
        customerIds: selectedContacts.map(customer => customer.id)
      })).then(() => {
        dispatch(
          updateShowSelectMode({
            modeName: 'showSelectContactMode',
            show: false,
          }),
        );
      });
    } else {
      message.info('Please select a route.');
    }
  };

  const openViewModal = (contact) => {
    dispatch(updateShowViewModal(true));
    dispatch(updateCustomerInvoicesLoading(true));
    dispatch(getInvoiceListByCustomer(contact));
  };

  const handleShare = () => {
    const text = selectedContacts.map(contact => `
      id: ${contact.id},
      customer name: ${contact.customer_name},
      email: ${contact.email},
      customer address: ${contact.customer_address},
      phone: ${contact.phone},
      city: ${contact.city},
      driver name: ${contact.driver_name},
      postcode: ${contact.postcode}
    `).join('\n');
    const textArea = document.createElement('textarea');
    textArea.value = text;
    document.body.appendChild(textArea);

    // 选择并复制文本
    textArea.select();
    textArea.setSelectionRange(0, 99999); // 针对移动设备

    document.execCommand('copy');
    document.body.removeChild(textArea);
    message.success('Customers copied to clipboard!');
  };

  const handleDelete = () => {
    if (selectedContacts.length > 0) {
      confirm({
        title: 'Delete confirm',
        icon: <ExclamationCircleFilled />,
        content: 'Confirm deleting？',
        async onOk() {
          dispatch(deleteContacts(selectedContacts.map(contact => contact.id)));
        },
        onCancel() {
          console.log('Cancel');
        },
      });
    } else {
      message.info('Please select at least one contact.');
    }
  };

  let selectContact = contacts.find((contact) => contact.id === contactId);

  return (
    <>
      <KModal
        isOpen={open}
        title={'Add New Contact'}
        onOpen={() => dispatch(updateOpen(true))}
        onClose={() => dispatch(updateOpen(false))}
      >
        <ContactForm
          addNewContact={addNewContact}
          onClose={() => dispatch(updateOpen(false))}
        />
      </KModal>
      <KModal
        isOpen={editOpen}
        title={'Update New Contact'}
        onOpen={() => dispatch(updateEditOpen(true))}
        onClose={() => dispatch(updateEditOpen(false))}
      >
        <ContactForm
          updateContact={_updateContact}
          contact={selectContact}
          onClose={() => dispatch(updateEditOpen(false))}
        />
      </KModal>
      <Modal
        title="Select Route"
        okText="Confirm"
        open={showSelectRoutesModal}
        onOk={handleAddCustomersToRoute}
        onCancel={() => dispatch(updateShowSelectRoutesModal(false))}
      >
        <Select
          placeholder="路线清单My Routes"
          style={{ width: '100%' }}
          value={selectedRouteForCustomers}
          onChange={handleRouteSelectedForCustomers}
        >
          {routes.map((route) => (
            <Option key={route.id} value={route.id}>
              {route.name}
            </Option>
          ))}
        </Select>
      </Modal>
      <ViewCustomerInvoicesModal />
      <div>
        <Flex className={styles.savedContacts} style={{ marginTop: "60px" }} justify="center" align="center">
          <img src="/banner.png" width="150px" height="100px" alt="banner" />
          <Title className={styles.title}>通讯录 Contacts</Title>
          <SavedContactsForm />
        </Flex>
        <div className={styles.pContent}>
          <Button
            type="primary"
            block
            onClick={() => dispatch(updateOpen(true))}
            icon={<PlusOutlined />}
          >
            Add Contact
          </Button>
        </div>
        <div className={styles.pContent}>
          <Select
            placeholder="Sort By"
            style={{ width: '100%' }}
            onChange={(value) => setSortOrder(value)}
          >
            <Option value="asc">Name A-Z</Option>
            <Option value="desc">Name Z-A</Option>
          </Select>
        </div>
        {/* Add New Buttons Below Sort By Dropdown */}
        <div className={styles.pContent}>
          <Select
            placeholder="路线清单My Routes"
            style={{ width: '100%' }}
            onChange={(value) => {
              if (value === "top") {
                handleRouteSelected(null);
              } else {
                handleRouteSelected(value);
              }
            }}
            allowClear
          >
            <Option value="top">Default (no chosen routes)</Option>
            {routes.map((route) => (
              <Option key={route.id} value={route.id}>
                {route.name}
              </Option>
            ))}
          </Select>
        </div>
        <div className={styles.pContent}>
          <Input
            size="large"
            type="text"
            placeholder="Search Contact..."
            prefix={<SearchOutlined />}
            value={searchData}
            onChange={(e) => dispatch(updateSearchData(e.target.value))}
          />
        </div>
        <div className={styles.pContent}>
          {searchContacts.length > 0 ? searchContacts.map((contact) => (
            <ContactCard
              contact={contact}
              key={contact.id}
              handleEdit={_openEditContact}
              deleteContact={_deleteContact}
              selecting={showSelectContactMode}
              selected={selectedContacts.some((c) => c.id === contact.id)}
              handleSelectChange={() => handleSelectChange(contact)}
              openViewModal={() => openViewModal(contact)}
            />
          )) : (
            <Empty />
          )}
        </div>
        {showSelectContactMode && (
          <div>
            <Flex className="actionTopLeft">
              <Button type="primary" onClick={handleSelectAll}>
                全选
              </Button>
            </Flex>
            <Flex className="actionTopRight">
              <Button type="primary" onClick={handleSelectCancel}>
                取消
              </Button>
            </Flex>
            <Flex className="actionBottomLeft">
              <Button type="primary" onClick={handleShare}>分享</Button>
              <Button type="primary" onClick={openSelectRouteModal}>添加到路线</Button>
            </Flex>
            <Flex className="actionBottomRight">
              <Button type="primary" onClick={handleDelete}>删除</Button>
            </Flex>
          </div>
        )}
      </div>
    </>
  );
};

export default Contacts;
