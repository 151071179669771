import React from 'react';
import {
  faEdit,
  faTrash,
  faEye,
  faTv,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from 'react-router-dom';
import styles from '../style/TodolistCard.module.css';
import { Flex, Typography, Checkbox } from 'antd';

const ContactCardNew = ({
  contact,
  handleEdit,
  deleteContact,
  selecting,
  selected,
  handleSelectChange,
  openViewModal
}) => {
  const updateHandler = (id) => {
    handleEdit(id);
  };

  const deleteContactHandler = (id) => {
    deleteContact(id);
  };

  // 根据 contact.city 选择卡片背景颜色的样式
  const getCardClass = () => {
    switch (contact.city) {
      case '高':
        return styles.high;
      case '中':
        return styles.medium;
      case '低':
        return styles.low;
      default:
        return styles.default;
    }
  };

  return (
    <>
      <Flex align="center" gap={10}>
        <Flex className={`${styles.container} ${getCardClass()}`}>
          <Link to={`/contact/${contact.id}`}>
            <Flex align="center">
              <div className={styles.mr4}>
                <FontAwesomeIcon size="3x" icon={faTv} />
              </div>
              {contact.product_name && (
                <div>
                  <Typography className={styles.text}>
                    {contact.product_name}
                  </Typography>
                  <Typography className={styles.text}>
                    {contact.price}
                  </Typography>
                </div>
              )}
              {contact.customer_name && (
                <div>
                  <Typography className={styles.text}>
                    {contact.customer_name}
                  </Typography>
                  <Typography className={styles.text}>
                    {contact.customer_address}
                  </Typography>
                </div>
              )}
            </Flex>
          </Link>

          <Flex align="center">
            <div className={styles.mr4} onClick={openViewModal}>
              <FontAwesomeIcon size="2x" icon={faEye} />
            </div>
            <div
              className={styles.mr4}
              onClick={() => updateHandler(contact.id)}
            >
              <FontAwesomeIcon size="2x" icon={faEdit} />
            </div>
            <div
              className={styles.mr4}
              onClick={() => deleteContactHandler(contact.id)}
            >
              <FontAwesomeIcon size="2x" icon={faTrash} />
            </div>
          </Flex>
        </Flex>
        {selecting && <Checkbox checked={selected} onChange={handleSelectChange} />}
      </Flex>
    </>
  );
};

export default ContactCardNew;
