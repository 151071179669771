import React, { useState, useEffect } from 'react';
import { Button, Image, Dropdown, Menu, message } from 'antd';
import {
  CameraOutlined,
  PictureOutlined,
  LinkOutlined,
  PlusOutlined,
} from '@ant-design/icons';

const convertToJpeg = async (file) => {
  return new Promise((resolve, reject) => {
    console.log("Converting HEIC to JPEG...");

    const reader = new FileReader();
    reader.onload = (event) => {
      const img = new Image();
      img.onload = () => {
        const canvas = document.createElement("canvas");
        canvas.width = img.width;
        canvas.height = img.height;
        const ctx = canvas.getContext("2d");
        ctx.drawImage(img, 0, 0);
        canvas.toBlob((blob) => {
          const jpegFile = new File([blob], file.name.replace(/\.\w+$/, ".jpeg"), { type: "image/jpeg" });
          console.log("JPEG file created:", jpegFile);
          resolve(jpegFile);
        }, "image/jpeg");
      };
      img.src = event.target.result;
    };
    reader.onerror = reject;
    reader.readAsDataURL(file);
  });
};

const ImageManager = ({ value = [], onChange }) => {
  const [tempLinks, setTempLinks] = useState(value);
  const [uploading, setUploading] = useState(false);

  useEffect(() => {
    setTempLinks(value);
  }, [value]);

  const handleUpload = async (file) => {
    try {
      setUploading(true);
      console.log("Uploading file:", file);

      if (file.type === "image/heic" || file.name.endsWith(".heic")) {
        file = await convertToJpeg(file);
        console.log("Converted to JPEG:", file);
      }

      const formData = new FormData();
      formData.append("product_image", file);

      const response = await fetch("https://www.butt-and-co.co.uk/upload_image_api/", {
        method: "POST",
        body: formData,
      });

      if (!response.ok) throw new Error("Upload failed");

      const data = await response.json();
      console.log("Response:", data);
      return data.image_link;

    } catch (error) {
      message.error("上传失败，请重试");
      throw error;
    } finally {
      setUploading(false);
    }
  };

  const handleFileSelection = async (event) => {
    let file = event.target.files[0];

    if (!file) {
      console.error("No file selected!");
      return;
    }

    console.log("File selected:", file);
    console.log("File type:", file.type);

    try {
      if (file.type === "image/heic" || file.name.endsWith(".heic")) {
        file = await convertToJpeg(file);
        console.log("Converted to JPEG:", file);
      }

      if (!file.type.startsWith("image/")) {
        console.error("Invalid file type:", file.type);
        message.error("无效的文件格式，请选择图片文件");
        return;
      }

      const link = await handleUpload(file);
      updateLinks([...tempLinks, link]);

    } catch (error) {
      console.error("Upload error:", error);
    }
  };

  const handleReplace = async (index) => {
    const newUrl = prompt("请输入新图片链接");
    if (newUrl) {
      if (/^(http|https):\/\/[^ "]+$/.test(newUrl)) {
        const updatedLinks = [...tempLinks];
        updatedLinks[index] = newUrl;
        updateLinks(updatedLinks);
      } else {
        message.error("无效的URL格式");
      }
    }
  };

  const actions = {
    takePhoto: () => {
      const input = document.createElement("input");
      input.type = "file";
      input.accept = "image/jpeg, image/png";
      input.capture = "user";
      input.onchange = handleFileSelection;
      input.click();
    },

    pickFromAlbum: () => {
      const input = document.createElement("input");
      input.type = "file";
      input.accept = "image/jpeg, image/png";
      input.onchange = handleFileSelection;
      input.click();
    },

    inputLink: () => {
      const url = prompt("请输入图片URL");
      if (url) {
        if (/^(http|https):\/\/[^ "]+$/.test(url)) {
          updateLinks([...tempLinks, url]);
        } else {
          message.error("无效的URL格式");
        }
      }
    }
  };

  const updateLinks = (newLinks) => {
    setTempLinks(newLinks);
    if (onChange) onChange(newLinks);
  };

  const menu = (
    <Menu>
      <Menu.Item icon={<CameraOutlined />} onClick={actions.takePhoto}>
        拍照上传
      </Menu.Item>
      <Menu.Item icon={<PictureOutlined />} onClick={actions.pickFromAlbum}>
        相册选择
      </Menu.Item>
      <Menu.Item icon={<LinkOutlined />} onClick={actions.inputLink}>
        输入链接
      </Menu.Item>
    </Menu>
  );

  return (
    <div style={{ display: "flex", flexWrap: "wrap", gap: 8 }}>
      {tempLinks.map((link, index) => (
        <ImageItem
          key={link}
          link={link}
          onDelete={() => updateLinks(tempLinks.filter((_, i) => i !== index))}
          onReplace={() => handleReplace(index)}
        />
      ))}

      <Dropdown overlay={menu} trigger={["click"]}>
        <Button loading={uploading} style={{ width: 80, height: 80, borderStyle: "dashed" }}>
          <PlusOutlined />
        </Button>
      </Dropdown>
    </div>
  );
};

const ImageItem = ({ link, onDelete, onReplace }) => (
  <div style={{ position: "relative" }}>
    <Image
      src={link}
      style={{
        width: 80,
        height: 80,
        objectFit: "cover",
        borderRadius: 4,
        border: "1px solid #eee",
      }}
      fallback={<div style={{ background: "#f5f5f5", width: 80, height: 80 }} />}
    />
    <Dropdown
      overlay={
        <Menu>
          <Menu.Item onClick={onReplace}>替换</Menu.Item>
          <Menu.Item onClick={onDelete}>删除</Menu.Item>
        </Menu>
      }
      trigger={["click"]}
    >
      <Button
        size="small"
        style={{
          position: "absolute",
          top: 2,
          right: 2,
          padding: 4,
          minWidth: 20,
          height: 20,
        }}
      >
        ⋮
      </Button>
    </Dropdown>
  </div>
);

export default ImageManager;
