import React, { useState } from 'react';
import { Button, Input, Form, Typography, Flex, message } from 'antd';

const { Item } = Form;

const ProductForm = () => {
  const [form] = Form.useForm();
  const [products, setProducts] = useState([
    {
      id: 1,
      hs_code: '',
      description: '',
      grossweight: '',
      netweight: '',
      cube: '',
      material: '',
      unit_value: '',
      ctns: '',
      quantity: '',
      total_value: '',
    },
  ]);

  const addProduct = () => {
    const newProduct = {
      id: products.length + 1,
      hs_code: '',
      description: '',
      grossweight: '',
      netweight: '',
      cube: '',
      material: '',
      unit_value: '',
      ctns: '',
      quantity: '',
      total_value: '',
    };
    setProducts([...products, newProduct]);
  };

  const handleChange = (index, field) => (e) => {
    const newProducts = [...products];
    newProducts[index][field] = e.target.value;
    setProducts(newProducts);
  };

  const handleMultiplyBy0_7 = (index) => () => {
    const newProducts = [...products];
    const unitValue = parseFloat(newProducts[index].unit_value) || 0;
    const totalValue = parseFloat(newProducts[index].total_value) || 0;

    newProducts[index].unit_value = (unitValue * 0.7).toFixed(2);
    newProducts[index].total_value = (totalValue * 0.7).toFixed(2);

    setProducts(newProducts);
  };

  const handleSubmit = async () => {
    try {
      // 将 products 数据转换为 JSON 字符串
      const jsonString = JSON.stringify(products, null, 2);

      // 复制到剪贴板
      navigator.clipboard
        .writeText(jsonString)
        .then(() => {
          message.success('Data copied to clipboard!');
        })
        .catch(() => {
          message.error('Failed to copy data to clipboard.');
        });

      console.log('Data to be submitted:', jsonString);
    } catch (error) {
      console.error('Error preparing data:', error);
      message.error('Error preparing data. Please try again.');
    }
  };

  return (
    <div
      style={{
        padding: '20px',
        height: 'calc(100vh - 120px)', // 减去顶部和底部导航栏的高度
        overflowY: 'auto', // 允许内容滚动
        marginTop: '60px', // 与顶部导航栏的距离
        marginBottom: '60px', // 与底部导航栏的距离
      }}
    >
      <Form form={form} layout="vertical">
        {products.map((product, index) => (
          <div
            key={product.id}
            style={{
              marginBottom: '20px',
              border: '1px solid #ddd',
              padding: '10px',
              borderRadius: '8px',
            }}
          >
            <Typography.Title level={4} style={{ marginBottom: '16px' }}>
              Product {index + 1}
            </Typography.Title>
            <Flex gap={16} wrap="wrap">
              <Item label="HS Code" style={{ flex: '1 1 200px' }}>
                <Input
                  value={product.hs_code}
                  onChange={handleChange(index, 'hs_code')}
                  placeholder="Enter HS Code"
                />
              </Item>
              <Item label="Description" style={{ flex: '1 1 200px' }}>
                <Input
                  value={product.description}
                  onChange={handleChange(index, 'description')}
                  placeholder="Enter Description"
                />
              </Item>
              <Item label="Gross Weight" style={{ flex: '1 1 200px' }}>
                <Input
                  value={product.grossweight}
                  onChange={handleChange(index, 'grossweight')}
                  placeholder="Enter Gross Weight"
                />
              </Item>
              <Item label="Net Weight" style={{ flex: '1 1 200px' }}>
                <Input
                  value={product.netweight}
                  onChange={handleChange(index, 'netweight')}
                  placeholder="Enter Net Weight"
                />
              </Item>
              <Item label="Cube" style={{ flex: '1 1 200px' }}>
                <Input
                  value={product.cube}
                  onChange={handleChange(index, 'cube')}
                  placeholder="Enter Cube"
                />
              </Item>
              <Item label="Material" style={{ flex: '1 1 200px' }}>
                <Input
                  value={product.material}
                  onChange={handleChange(index, 'material')}
                  placeholder="Enter Material"
                />
              </Item>
              <Item label="Unit Value" style={{ flex: '1 1 200px' }}>
                <Input
                  value={product.unit_value}
                  onChange={handleChange(index, 'unit_value')}
                  placeholder="Enter Unit Value"
                />
              </Item>
              <Item label="CTNs" style={{ flex: '1 1 200px' }}>
                <Input
                  value={product.ctns}
                  onChange={handleChange(index, 'ctns')}
                  placeholder="Enter CTNs"
                />
              </Item>
              <Item label="Quantity" style={{ flex: '1 1 200px' }}>
                <Input
                  value={product.quantity}
                  onChange={handleChange(index, 'quantity')}
                  placeholder="Enter Quantity"
                />
              </Item>
              <Item label="Total Value" style={{ flex: '1 1 200px' }}>
                <Input
                  value={product.total_value}
                  onChange={handleChange(index, 'total_value')}
                  placeholder="Enter Total Value"
                />
              </Item>
            </Flex>
            <Button
              type="primary"
              onClick={handleMultiplyBy0_7(index)}
              style={{ marginTop: '10px' }}
            >
              乘0.7
            </Button>
          </div>
        ))}
        <Button
          type="dashed"
          onClick={addProduct}
          style={{ width: '100%', marginBottom: '20px' }}
        >
          Add New Product
        </Button>
        <Button type="primary" onClick={handleSubmit} style={{ width: '100%' }}>
          Submit
        </Button>
      </Form>
    </div>
  );
};

export default ProductForm;