import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import * as supplierContactAPI from '../../apis/supplier-contact';
import { message } from 'antd';

// 供应商联系人 Thunks
export const getSupplierContactsList = createAsyncThunk(
  'supplierContact/list',
  supplierContactAPI.getAllSupplierContacts
);

export const createSupplierContact = createAsyncThunk(
  'supplierContact/create',
  supplierContactAPI.addSupplierContactOnServer
);

export const updateSupplierContact = createAsyncThunk(
  'supplierContact/update',
  supplierContactAPI.updateSupplierContactOnServer
);

export const deleteSupplierContact = createAsyncThunk(
  'supplierContact/delete',
  supplierContactAPI.deleteSupplierContactOnServer
);

export const deleteSupplierContacts = createAsyncThunk(
  'supplierContact/delete/batch',
  supplierContactAPI.deleteSupplierContactsOnServer
);

export const getSupplierContact = createAsyncThunk(
  'supplierContact/get',
  supplierContactAPI.getSupplierContactById
);

export const updateSupplierContactCustomer = createAsyncThunk(
  'supplierContact/update/customer',
  supplierContactAPI.updateSupplierCustomer
);

// 供应商路线 Thunks
export const getSupplierRoutesList = createAsyncThunk(
  'supplierRoute/list',
  supplierContactAPI.getAllSupplierRoutes
);

export const createSupplierRoute = createAsyncThunk(
  'supplierRoute/create',
  supplierContactAPI.createSupplierRoute
);

export const getSupplierRouteDetails = createAsyncThunk(
  'supplierRoute/details',
  supplierContactAPI.getSupplierRouteById
);

export const updateSupplierRouteDetails = createAsyncThunk(
  'supplierRoute/update',
  async (updateData, thunkAPI) => {
    return await supplierContactAPI.updateSupplierRoute(updateData.id, updateData, thunkAPI);
  }
);

export const deleteSupplierRouteById = createAsyncThunk(
  'supplierRoute/delete',
  supplierContactAPI.deleteSupplierRoute
);

export const addSupplierCustomerToRouteThunk = createAsyncThunk(
  'supplierRoute/customer/add',
  async ({ routeId, customerIds }, thunkAPI) => {
    return await supplierContactAPI.addSupplierCustomerToRoute(routeId, customerIds, thunkAPI);
  }
);

export const removeSupplierCustomerFromRouteThunk = createAsyncThunk(
  'supplierRoute/customer/remove',
  async ({ routeId, customerIds }, thunkAPI) => {
    return await supplierContactAPI.removeSupplierCustomerFromRoute(routeId, customerIds, thunkAPI);
  }
);

export const getSupplierRouteCustomersThunk = createAsyncThunk(
  'supplierRoute/customers',
  supplierContactAPI.getSupplierRouteCustomers
);

const supplierContactSlice = createSlice({
  name: 'supplierContact',
  initialState: {
    contacts: [],
    routes: [],
    routeDetails: undefined,
    contact: undefined,
    open: false,
    editOpen: false,
    searchData: '',
    contactId: undefined,
    responseMessage: null,
    responseColor: '#000000',
    showSelectContactMode: false,
    selectedContacts: [],
    selectedRoute: undefined,
    showSelectRoutesModal: false,
    selectedRouteForCustomers: undefined,
    showViewModal: false,
    customerInvoiceDetail: {},
    customerInvoicesLoading: false
  },
  reducers: {
    updateSupplierContactId: (state, action) => {
      state.contactId = action.payload;
    },
    updateSupplierOpen: (state, action) => {
      state.open = action.payload;
    },
    updateSupplierEditOpen: (state, action) => {
      state.editOpen = action.payload;
    },
    updateSupplierSearchData: (state, action) => {
      state.searchData = action.payload;
    },
    updateSupplierResponseMessage: (state, action) => {
      state.responseMessage = action.payload;
    },
    updateSupplierResponseColor: (state, action) => {
      state.responseColor = action.payload;
    },
    updateSupplierShowSelectContactMode: (state, action) => {
      state.showSelectContactMode = action.payload;
    },
    updateSupplierSelectedContacts: (state, action) => {
      state.selectedContacts = action.payload;
    },
    updateSupplierSelectedRoute: (state, action) => {
      state.selectedRoute = action.payload;
    },
    updateSupplierRouteDetailsName: (state, action) => {
      state.routeDetails = { ...state.routeDetails, name: action.payload };
    },
    updateSupplierShowSelectRoutesModal: (state, action) => {
      state.showSelectRoutesModal = action.payload;
    },
    updateSupplierSelectedRouteForCustomers: (state, action) => {
      state.selectedRouteForCustomers = action.payload;
    },
    updateSupplierShowViewModal: (state, action) => {
      state.showViewModal = action.payload;
    },
    updateSupplierCustomerInvoicesLoading: (state, action) => {
      state.customerInvoicesLoading = action.payload;
    },
  },
  extraReducers: (builder) => {
    // 供应商联系人
    builder.addCase(getSupplierContactsList.fulfilled, (state, action) => {
      const data = action.payload;
      const tempArray = [];
      if (data !== null) {
        Object.entries(data).forEach(([key, value]) => {
          tempArray.push({ id: key, ...value });
        });
      }
      state.contacts = tempArray;
    });

    builder.addCase(createSupplierContact.fulfilled, (state, action) => {
      state.contacts = [...state.contacts, action.payload];
      message.success('Supplier Added Success!');
    });

    builder.addCase(updateSupplierContact.fulfilled, (state, action) => {
      const { id, ...rest } = action.payload;
      const matchedContact = state.contacts.find((contact) => contact.id === id);
      if (matchedContact) {
        Object.keys(rest).forEach((key) => {
          matchedContact[key] = rest[key];
        });
      }
      state.contacts = [...state.contacts];
      message.success('Supplier Update Success!');
    });

    builder.addCase(deleteSupplierContact.fulfilled, (state, action) => {
      state.contacts = state.contacts.filter(
        (contact) => contact.id !== action.meta.arg
      );
      message.success('Supplier Delete Success!');
    });

    builder.addCase(deleteSupplierContacts.fulfilled, (state, action) => {
      state.contacts = state.contacts.filter(
        (contact) => !action.meta.arg.includes(contact.id)
      );
      message.success('Suppliers Deleted!');
    });

    builder.addCase(getSupplierContact.fulfilled, (state, action) => {
      state.contact = action.payload;
    });

    builder.addCase(updateSupplierContactCustomer.fulfilled, (state, action) => {
      // 此处根据需求添加逻辑，目前保持空处理
    });

    // 供应商路线
    builder.addCase(getSupplierRoutesList.fulfilled, (state, action) => {
      state.routes = action.payload || [];
    });

    builder.addCase(createSupplierRoute.fulfilled, (state, action) => {
      state.routes = [...state.routes, action.payload];
      message.success('Supplier Route Created!');
    });

    builder.addCase(getSupplierRouteDetails.fulfilled, (state, action) => {
      state.routeDetails = action.payload;
      // state.contacts = Object.values(action.payload.customers).map(item => item.customer);
    });

    builder.addCase(updateSupplierRouteDetails.fulfilled, (state, action) => {
      const { id, ...rest } = action.payload;
      const matchedRoute = state.routes.find((route) => route.id === id);
      if (matchedRoute) {
        Object.keys(rest).forEach((key) => {
          matchedRoute[key] = rest[key];
        });
      }
      state.routes = [...state.routes];
      message.success('Route Updated!');
    });

    builder.addCase(deleteSupplierRouteById.fulfilled, (state, action) => {
      state.routes = state.routes.filter(
        (route) => route.id !== action.meta.arg
      );
      message.success('Route Deleted!');
    });

    builder.addCase(addSupplierCustomerToRouteThunk.fulfilled, (state) => {
      state.showSelectRoutesModal = false;
      state.selectedContacts = [];
      message.success('Added to Supplier Route!');
    });

    builder.addCase(removeSupplierCustomerFromRouteThunk.fulfilled, (state, action) => {
      message.success('Supplier removed from route successfully!');
      state.routeDetails = {
        ...state.routeDetails,
        customers: state.routeDetails.customers.filter(item => !action.meta.arg.customerIds.includes(item.customer.id)),
      };
    });

    builder.addCase(getSupplierRouteCustomersThunk.fulfilled, (state, action) => {
      state.routeDetails = { ...state.routeDetails, customers: action.payload };
      message.success('Supplier Route customers loaded!');
    });

    // 处理 invoice/list/customer/fulfilled 的情况
    builder.addCase('invoice/list/customer/fulfilled', (state, action) => {
      state.customerInvoiceDetail = action.payload;
      state.customerInvoicesLoading = false;
    });
  },
});

export const {
  updateSupplierContactId,
  updateSupplierOpen,
  updateSupplierEditOpen,
  updateSupplierSearchData,
  updateSupplierResponseMessage,
  updateSupplierResponseColor,
  updateSupplierShowSelectContactMode,
  updateSupplierSelectedContacts,
  updateSupplierSelectedRoute,
  updateSupplierRouteDetailsName,
  updateSupplierShowSelectRoutesModal,
  updateSupplierSelectedRouteForCustomers,
  updateSupplierShowViewModal,
  updateSupplierCustomerInvoicesLoading,
} = supplierContactSlice.actions;

export default supplierContactSlice.reducer;
