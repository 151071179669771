import { createSlice } from '@reduxjs/toolkit';

const getInitialCompanyInfo = () => {
  let info = localStorage.getItem('user_info');
  info = info ? JSON.parse(info) : null;

  if (info && Number(info.driver) === 2) {
    return {
      name: info.email,
      email: info.email,
      registrationNumber: '',
      bankAccount: '',
      bankSortCode: '',
      vatNumber: '',
      invoice_number: '',
      Contact_number: '',
      Contact_person: '',
    };
  }

  return {
    name: 'BUTT & CO (UK) Ltd',
    email: 'lee@butt-and-co.co.uk',
    registrationNumber: '10445756',
    bankAccount: '10478849',
    bankSortCode: '04-00-04',
    vatNumber: '324855100',
    invoice_number: 'auto_110801',
    Contact_number: '07455025081',
    Contact_person: 'Lee',
  };
};

const commonSlice = createSlice({
  name: 'common',
  initialState: {
    selecting: false,
    showSelectContactMode: false,
    showSelectGetPaidMode: false,
    showSelectProductMode: false,
    showSelectScheduleMode: false,
    showSelectCreditMode: false,
    showCompanyInfoModal: false,
    showSelectTodoListMode: false,
    companyInfo: getInitialCompanyInfo(),
  },
  reducers: {
    updateSelecting: (state, action) => {
      state.selecting = action.payload;
    },
    updateShowSelectMode: (state, action) => {
      state.showSelectContactMode = false;
      state.showSelectGetPaidMode = false;
      state.showSelectProductMode = false;
      state.showSelectScheduleMode = false;
      state.showSelectCreditMode = false;
      state.showSelectTodoListMode = false;
      state.selecting = action.payload.show;
      state[action.payload.modeName] = action.payload.show;
    },
    updateShowCompanyInfoModal: (state, action) => {
      state.showCompanyInfoModal = action.payload;
    },
    updateCompanyInfo: (state, action) => {
      state.companyInfo = action.payload;
    },
  },
});

export const {
  updateSelecting,
  updateShowSelectMode,
  updateShowCompanyInfoModal,
  updateCompanyInfo,
} = commonSlice.actions;

export default commonSlice.reducer;
