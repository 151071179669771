import { getUserInfo } from './user';
import axios from 'axios';

const REACT_APP_SERVER = process.env.REACT_APP_SERVER;

// 供应商联系人 API
export const addSupplierContactOnServer = async (obj, thunkAPI) => {
  try {
    const { customer_name, email, customer_address, city, postcode, phone } = obj;
    const userInfo = getUserInfo();
    const { data } = await axios.post(
      `${REACT_APP_SERVER}/create-driver-customer-todo/`,
      {
        customer_name,
        email,
        ...userInfo,
        customer_address,
        city,
        postcode,
        phone,
      },
    );
    return data;
  } catch (error) {
    return thunkAPI.rejectWithValue(error.message);
  }
};

export const getAllSupplierContacts = async (thunkAPI) => {
  try {
    const userInfo = getUserInfo();
    const { data } = await axios.get(
      `${REACT_APP_SERVER}/api-todo/responsible_persons/${userInfo.id}/customers/`
    );
    return data;
  } catch (error) {
    return thunkAPI.rejectWithValue(error.message);
  }
};

export const getAllSupplierRoutes = async (thunkAPI) => {
  try {
    const userInfo = getUserInfo();
    const { data } = await axios.get(
      `${REACT_APP_SERVER}/routeapi-todo/responsible-persons/${userInfo.id}/routes-todo/`
    );
    return data;
  } catch (error) {
    return thunkAPI.rejectWithValue(error.message);
  }
};

// 路线相关 API
export const createSupplierRoute = async (routeData, thunkAPI) => {
  try {
    const userInfo = getUserInfo();
    const { data } = await axios.post(
      `${REACT_APP_SERVER}/routeapi-todo/responsible-persons/${userInfo.id}/routes-todo/`,
      routeData,
    );
    return data;
  } catch (error) {
    return thunkAPI.rejectWithValue(error.message);
  }
};

export const getSupplierRouteById = async (routeId, thunkAPI) => {
  try {
    const userInfo = getUserInfo();
    const { data } = await axios.get(
      `${REACT_APP_SERVER}/routeapi-todo/responsible-persons/${userInfo.id}/routes-todo/${routeId}/`
    );
    return data;
  } catch (error) {
    return thunkAPI.rejectWithValue(error.message);
  }
};

export const updateSupplierRoute = async (routeId, updatedData, thunkAPI) => {
  try {
    const userInfo = getUserInfo();
    const { data } = await axios.put(
      `${REACT_APP_SERVER}/routeapi-todo/responsible-persons/${userInfo.id}/routes-todo/${routeId}/`,
      updatedData,
    );
    return data;
  } catch (error) {
    return thunkAPI.rejectWithValue(error.message);
  }
};

export const deleteSupplierRoute = async (routeId, thunkAPI) => {
  try {
    const userInfo = getUserInfo();
    const { data } = await axios.delete(
      `${REACT_APP_SERVER}/routeapi-todo/responsible-persons/${userInfo.id}/routes-todo/${routeId}/`
    );
    return data;
  } catch (error) {
    return thunkAPI.rejectWithValue(error.message);
  }
};

export const addSupplierCustomerToRoute = async (routeId, customerIds, thunkAPI) => {
  try {
    const userInfo = getUserInfo();
    const { data } = await axios.post(
      `${REACT_APP_SERVER}/routeapi-todo/responsible-persons/${userInfo.id}/routes-todo/${routeId}/add_customer/`,
      { customer_ids: customerIds },
    );
    return data;
  } catch (error) {
    return thunkAPI.rejectWithValue(error.message);
  }
};

export const removeSupplierCustomerFromRoute = async (routeId, customerIds, thunkAPI) => {
  try {
    const userInfo = getUserInfo();
    const { data } = await axios.post(
      `${REACT_APP_SERVER}/routeapi-todo/responsible-persons/${userInfo.id}/routes-todo/${routeId}/remove_customer/`,
      { customer_ids: customerIds },
    );
    return data;
  } catch (error) {
    return thunkAPI.rejectWithValue(error.message);
  }
};

export const getSupplierRouteCustomers = async (routeId, thunkAPI) => {
  try {
    const userInfo = getUserInfo();
    const { data } = await axios.get(
      `${REACT_APP_SERVER}/routeapi-todo/responsible-persons/${userInfo.id}/routes-todo/${routeId}/customers/`
    );
    return data;
  } catch (error) {
    return thunkAPI.rejectWithValue(error.message);
  }
};

// 联系人操作
export const updateSupplierContactOnServer = async (obj, thunkAPI) => {
  try {
    const { customer_name, email, id, customer_address, city, postcode, phone } = obj;
    const userInfo = getUserInfo();
    const { data } = await axios.patch(
      `${REACT_APP_SERVER}/api-todo/responsible_persons_edit/${userInfo.id}/customers/${id}/`,
      {
        customer_name,
        email,
        ...userInfo,
        customer_address,
        city,
        postcode,
        phone,
      },
    );
    return data;
  } catch (error) {
    return thunkAPI.rejectWithValue(error.message);
  }
};

export const deleteSupplierContactOnServer = async (id, thunkAPI) => {
  try {
    const userInfo = getUserInfo();
    const { data } = await axios.delete(
      `${REACT_APP_SERVER}/api-todo/responsible_persons_edit/${userInfo.id}/customers/${id}/`
    );
    return data;
  } catch (error) {
    return thunkAPI.rejectWithValue(error.message);
  }
};

export const deleteSupplierContactsOnServer = async (ids, thunkAPI) => {
  try {
    const userInfo = getUserInfo();
    const { data } = await axios.delete(
      `${REACT_APP_SERVER}/api-todo/responsible_persons_edit/${userInfo.id}/customers/bulk_delete/`,
      {
        data: {
          customer_ids: ids
        }
      },
    );
    return data;
  } catch (error) {
    return thunkAPI.rejectWithValue(error.message);
  }
};

export const getSupplierContactById = async (id, thunkAPI) => {
  try {
    const userInfo = getUserInfo();
    const { data } = await axios.get(
      `${REACT_APP_SERVER}/api-todo/responsible_persons_edit/${userInfo.id}/customers/${id}/`
    );
    return data;
  } catch (error) {
    return thunkAPI.rejectWithValue(error.message);
  }
};

export const updateSupplierCustomer = async (obj, thunkAPI) => {
  try {
    const { data } = await axios.post(
      `${REACT_APP_SERVER}/api-todo/user/update_customer_laokehu/`,
      obj,
    );
    return data;
  } catch (error) {
    return thunkAPI.rejectWithValue(error.message);
  }
};
