import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import {
  addContactOnServer,
  deleteContactOnServer,
  getAllContacts,
  getAllRoutes,
  getContactById,
  updateContactOnServer,
  updateCustomer,
  createRoute,
  getRouteById,
  updateRoute,
  deleteRoute,
  addCustomerToRoute,
  removeCustomerFromRoute,
  getRouteCustomers, deleteContactsOnServer,
} from '../../apis/contact';
import { message } from 'antd';

// Contacts Thunks
export const getContactsList = createAsyncThunk('contact/list', getAllContacts);

export const createContact = createAsyncThunk(
  'contact/create',
  addContactOnServer,
);

export const updateContact = createAsyncThunk(
  'contact/update',
  updateContactOnServer,
);

export const deleteContact = createAsyncThunk(
  'contact/delete',
  deleteContactOnServer,
);

export const deleteContacts = createAsyncThunk(
  'contact/delete/batch',
  deleteContactsOnServer,
);

export const getContact = createAsyncThunk('contact/get', getContactById);

export const updateContactCustomer = createAsyncThunk(
  'contact/update/customer',
  updateCustomer,
);

// Routes Thunks
export const getRoutesList = createAsyncThunk('route/list', getAllRoutes);

export const createNewRoute = createAsyncThunk('route/create', createRoute);

export const getRouteDetails = createAsyncThunk('route/details', getRouteById);

export const updateRouteDetails = createAsyncThunk(
  'route/update',
  async (updateData, thunkAPI) => {
    return await updateRoute(updateData.id, updateData, thunkAPI)
  }
);

export const deleteRouteById = createAsyncThunk('route/delete', deleteRoute);

export const addCustomerToRouteThunk = createAsyncThunk(
  'route/customer/add',
  async ({ routeId, customerIds }, thunkAPI) => {
    return await addCustomerToRoute(routeId, customerIds, thunkAPI);
  },
);

export const removeCustomerFromRouteThunk = createAsyncThunk(
  'route/customer/remove',
  async ({ routeId, customerIds }, thunkAPI) => {
    return await removeCustomerFromRoute(routeId, customerIds, thunkAPI);
  },
);

export const getRouteCustomersThunk = createAsyncThunk(
  'route/customers',
  getRouteCustomers,
);

// Contact and Route Slice
const contactSlice = createSlice({
  name: 'contact',
  initialState: {
    contacts: [],
    routes: [],
    routeDetails: undefined,
    contact: undefined,
    open: false,
    editOpen: false,
    searchData: '',
    contactId: undefined,
    responseMessage: null,
    responseColor: '#000000',
    selectedContacts: [],
    selectedRoute: undefined,
    showSelectRoutesModal: false,
    selectedRouteForCustomers: undefined,
    showViewModal: false,
    customerInvoiceDetail: {},
    customerInvoicesLoading: false
  },
  reducers: {
    updateContactId: (state, action) => {
      state.contactId = action.payload;
    },
    updateOpen: (state, action) => {
      state.open = action.payload;
    },
    updateEditOpen: (state, action) => {
      state.editOpen = action.payload;
    },
    updateSearchData: (state, action) => {
      state.searchData = action.payload;
    },
    updateResponseMessage: (state, action) => {
      state.responseMessage = action.payload;
    },
    updateResponseColor: (state, action) => {
      state.responseColor = action.payload;
    },
    updateShowSelectContactMode: (state, action) => {
      state.showSelectContactMode = action.payload;
    },
    updateSelectedContacts: (state, action) => {
      state.selectedContacts = action.payload;
    },
    updateSelectedRoute: (state, action) => {
      state.selectedRoute = action.payload;
    },
    updateRouteDetailsName: (state, action) => {
      state.routeDetails = { ...state.routeDetails, name: action.payload };
    },
    updateShowSelectRoutesModal: (state, action) => {
      state.showSelectRoutesModal = action.payload;
    },
    updateSelectedRouteForCustomers: (state, action) => {
      state.selectedRouteForCustomers = action.payload;
    },
    updateShowViewModal: (state, action) => {
      state.showViewModal = action.payload;
    },
    updateCustomerInvoicesLoading: (state, action) => {
      state.customerInvoicesLoading = action.payload;
    },
  },
  extraReducers: (builder) => {
    // Contacts
    builder.addCase(getContactsList.fulfilled, (state, action) => {
      const data = action.payload;
      const tempArray = [];
      if (data !== null) {
        Object.entries(data).forEach(([key, value]) => {
          tempArray.push({ id: key, ...value });
        });
      }
      state.contacts = tempArray;
    });

    builder.addCase(createContact.fulfilled, (state, action) => {
      state.contacts = [...state.contacts, action.payload];
      message.success('Add Success!');
    });

    builder.addCase(updateContact.fulfilled, (state, action) => {
      const { id, ...rest } = action.payload;
      const matchedContact = state.contacts.find(
        (contact) => contact.id === id,
      );
      if (matchedContact) {
        Object.keys(rest).forEach((key) => {
          matchedContact[key] = rest[key];
        });
      }
      state.contacts = [...state.contacts];
      message.success('Update Success!');
    });

    builder.addCase(deleteContact.fulfilled, (state, action) => {
      state.contacts = state.contacts.filter(
        (contact) => contact.id !== action.meta.arg,
      );
      message.success('Delete Success!');
    });

    builder.addCase(deleteContacts.fulfilled, (state, action) => {
      state.contacts = state.contacts.filter(
        (contact) => !action.meta.arg.includes(contact.id),
      );
      message.success('Delete Success!');
    });

    builder.addCase(getContact.fulfilled, (state, action) => {
      state.contact = action.payload;
    });

    builder.addCase(updateContactCustomer.fulfilled, (state, action) => {});

    // Routes
    builder.addCase(getRoutesList.fulfilled, (state, action) => {
      state.routes = action.payload || [];
    });

    builder.addCase(createNewRoute.fulfilled, (state, action) => {
      state.routes = [...state.routes, action.payload];
      message.success('Route created successfully!');
    });

    builder.addCase(getRouteDetails.fulfilled, (state, action) => {
      state.routeDetails = action.payload;
      // state.contacts = Object.values(action.payload.customers).map(item => item.customer)
    });

    builder.addCase(updateRouteDetails.fulfilled, (state, action) => {
      const { id, ...rest } = action.payload;
      const matchedRoute = state.routes.find((route) => route.id === id);
      if (matchedRoute) {
        Object.keys(rest).forEach((key) => {
          matchedRoute[key] = rest[key];
        });
      }
      state.routes = [...state.routes];
      message.success('Route updated successfully!');
    });

    builder.addCase(deleteRouteById.fulfilled, (state, action) => {
      state.routes = state.routes.filter(
        (route) => route.id !== action.meta.arg,
      );
      message.success('Route deleted successfully!');
    });

    builder.addCase(addCustomerToRouteThunk.fulfilled, (state) => {
      state.showSelectRoutesModal = false
      state.selectedContacts = []
      message.success('Customer added to route successfully!');
    });

    builder.addCase(removeCustomerFromRouteThunk.fulfilled, (state, action) => {
      state.routeDetails = {
        ...state.routeDetails,
        customers: state.routeDetails.customers.filter(item => !action.meta.arg.customerIds.includes(item.customer.id)),
      };
      message.success('Customer removed from route successfully!');
    });

    builder.addCase(getRouteCustomersThunk.fulfilled, (state, action) => {
      state.routeDetails = {
        ...state.routeDetails,
        customers: action.payload,
      };
      message.success('Route customers loaded successfully!');
    });
    builder.addCase('invoice/list/customer/fulfilled', (state, action) => {
      state.customerInvoiceDetail = action.payload
      state.customerInvoicesLoading = false
    });
  },
});

export const {
  updateContactId,
  updateOpen,
  updateEditOpen,
  updateSearchData,
  updateResponseMessage,
  updateResponseColor,
  updateSelectedContacts,
  updateShowSelectContactMode,
  updateSelectedRoute,
  updateRouteDetailsName,
  updateShowSelectRoutesModal,
  updateSelectedRouteForCustomers,
  updateShowViewModal,
  updateCustomerInvoicesLoading
} = contactSlice.actions;

export default contactSlice.reducer;
